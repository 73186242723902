import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getProjectsInVault,
  deleteProjectInVault,
  renameProject,
} from "../../redux/user/actions";
import classnames from "classnames";
import { swal } from "react-redux-sweetalert2";
import { ClipLoader } from "halogenium";
import { isTablet } from "react-device-detect";
import $ from "jquery";

class MyProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wiggleProjects: [],
      flipBookProjects: [],
      advancedProjects: [],
      projectData: [],
      activeProject: "",
      activeProjectType: "",
      showWiggleLoader: false,
      showFlipbookLoader: false,
      showAdvancedLoader: false,
    };
  }
  componentDidMount() {
    // let projectObj = {
    //   userId: this.props.user.userDetails.id,
    //   category: this.props.category,
    // };
    // this.props.getProjectsInVault(projectObj, (res) => {
    //   this.setState({
    //     projectData: res,
    //     showLoader: false,
    //   });
    // });
    // activeProject = (id, action = '') => {
    //  const data = {
    //      activeId: id
    //  };
    //  // reset edit/rename popup when we click open
    //  if(action) data.editProject = false;
    //  this.setState({...data});
    // }
    // loadProject = (id) => {
    //  this.props.loadProjectFromPopup(id);
    // }
    let { userDetails } = this.props.user;
    this.setState({
      showWiggleLoader: true,
      showFlipbookLoader: true,
      showAdvancedLoader: true,
    });
    let wiggleObj = {
        userId: userDetails.id,
        category: "wiggledoodle",
      },
      flipbookObj = {
        userId: userDetails.id,
        category: "flipbook",
      },
      advancedObj = {
        userId: userDetails.id,
        category: "advanced-doodle",
      };
    this.props.getProjectsInVault(wiggleObj, (res) => {
      this.setState({
        wiggleProjects: res,
        showWiggleLoader: false,
      });
    });
    this.props.getProjectsInVault(flipbookObj, (res) => {
      this.setState({
        flipBookProjects: res,
        showFlipbookLoader: false,
      });
    });
    this.props.getProjectsInVault(advancedObj, (res) => {
      this.setState({
        advancedProjects: res,
        showAdvancedLoader: false,
      });
    });
  }

  deleteProject = (id, type) => {
    if (this.props.canvas.currentProject._id == id) {
      this.props.swal({
        title: "Info",
        text: "Can't delete current loaded project.",
        type: "info",
        confirmAlert: () => false,
      });
      return;
    }
    this.props.swal({
      title: "Info!",
      text: "Are you sure you want to delete this?",
      type: "info",
      showConfirmButton: true,
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmAlert: () => {
        this.props.deleteProjectInVault({ _id: id }, (res) => {
          let allProjects = [...this.state[type]];
          const index = allProjects.findIndex((e) => e._id == id);
          if (index > -1) {
            allProjects.splice(index, 1);
            this.setState({
              [type]: allProjects,
            });
          }
        });
      },
      cancelCallback: () => {},
    });
  };
  renameProject = () => {
    const newProjectName = this.state.blankProjectName.trim();
    const newObjName = this.state.editType.trim();
    if (!newProjectName) {
      this.setState({ hasError: true });
      return;
    } else {
      this.setState({ hasError: false });
    }
    let allProjects = [...this.state[newObjName]];
    const index = allProjects.findIndex((e) => e._id == this.state.activeId);
    if (index > -1) {
      const currentProject = allProjects[index];
      if (currentProject.name === newProjectName) {
        this.setState({ editProject: false });
      } else {
        const payload = {
          _id: currentProject._id,
          name: newProjectName,
          userId: this.props.user.userDetails.id,
          category: this.props.category,
        };
        this.props.renameProject(payload, (res) => {
          allProjects[index].name = res.result.project.name;
          this.setState({
            [newObjName]: allProjects,
            editProject: false,
          });
        });
        //Todo update project name in store
      }
      this.closeModel();
    }
  };
  showEditProjectUI = (id, type) => {
    let allProjects = [...this.state[type]];
    const index = allProjects.findIndex((e) => e._id == id);
    this.setState({
      activeId: id,
      editProject: true,
      editType: type,
      blankProjectName: allProjects[index].name,
      hasError: false,
    });
    $("#projectListing").trigger("click");
  };
  closeModel = () => {
    $("#projectListing").trigger("click");
    this.setState({
      editProject: false,
    });
  };
  renderProjectsName(data, objName) {
    let { activeProject, showWiggleLoader } = this.state;
    if (showWiggleLoader) {
      return (
        <div className="projectVaultLoader">
          <ClipLoader color="#33A5C9" size="22px" margin="4px" />
        </div>
      );
    }
    const config = {
      wiggleProjects: "wiggledoodle",
      flipBookProjects: "flipbook",
      advancedProjects: "advanced-doodle",
    };
    const url = `/${config[objName]}`;
    return (
      data.length > 0 &&
      data.map((item, index) => {
        return (
          <li key={index}>
            <div
              className={classnames({
                active_project_in_vault: activeProject._id === item._id,
                art_inner_col: true,
              })}
              
              onDoubleClick={() => {
                this.props.history.push(url, item);
              }}
              onClick={() => {
                if (isTablet) {
                  this.props.history.push(url, item);
                }
                this.setState({
                  projectData: item,
                  activeProjectType: objName,
                });
              }}
            >
              <span
              style={{
                cursor: 'pointer',
              }}
              >
                <img src={item.keyframesBase64} />
              </span>
              <span style={{
                cursor: 'pointer',
              }}
              title="Give the project file two quick clicks."
              >
                {/* {<img src="images/advanced/icon1text.png" />} */}
                {item.name}
              </span>
              <span className="actions" style={{display: 'flex', gap: '5px'}}>
                <i
                  className="fa fa-pencil-square-o"
                  aria-hidden="true"
                  onClick={() => this.showEditProjectUI(item._id, objName)}
                ></i>
                <i
                  className={classnames({
                    "fa fa-trash": true,
                    disable: this.props.canvas.currentProject._id === item._id,
                  })}
                  aria-hidden="true"
                  onClick={() => this.deleteProject(item._id, objName)}
                ></i>
              </span>
            </div>
          </li>
        );
      })
    );
  }
  openSelectedProject() {
    let { activeProject, activeProjectType } = this.state;
    if (activeProjectType)
      this.props.history.push(`/${activeProjectType}`, activeProject);
  }
  cannotBrowse() {
    this.props.swal({
      title: "Oops!",
      text: "Projects are saved over the cloud, cannot browse through them in the local system..",
      type: "info",
      confirmAlert: () => false,
    });
  }
  render() {
    let { wiggleProjects, flipBookProjects, advancedProjects } = this.state;
    let { userDetails } = this.props.user;
    let data = {
      wiggleProjects,
      flipBookProjects,
      advancedProjects,
    };
    return (
      <div className="body_wrap">
        <div className="conta_iner">
          <div className="animation_ish_box">
            <div className="project_vault_section">
              <div className="main_logo_vault">
                <img src="https://d3rc7k2n78msdl.cloudfront.net/myproject/my_projects.png" />
              </div>
              <div className="main_mid_projectvault_sec">
                <div className="main_mid_img">
                  <img style={{width: "1160px", height: "450px"}} src="https://d3rc7k2n78msdl.cloudfront.net/project/main_box.png" />
                  <div className="projectvault_overlay">
                    <ul>
                      {data &&
                        Object.keys(data).map((objName, index) => (
                          <li>
                            <div className="vault_logo">
                              <img
                                src={`https://d3rc7k2n78msdl.cloudfront.net/project/icon-${index + 1}.png`}
                              />
                            </div>
                            <div className="bault_small_box">
                              <img src="https://d3rc7k2n78msdl.cloudfront.net/project/small_box.png" />
                              <div className="art_list">
                                <ul>
                                  {this.renderProjectsName(
                                    data[objName],
                                    objName
                                  )}
                                </ul>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="open_or_home_buttons">
                <div
                  title="Open Project Vault"
                  className="left_open_buttons"
                  onClick={() => this.props.history.push("project-vault")}
                >
                  <img src="https://d3rc7k2n78msdl.cloudfront.net/myproject/open_projectvault.png" />
                </div>
                <div className="left_open_buttons">
                  <Link to="/dashboard">
                    <img src="https://d3rc7k2n78msdl.cloudfront.net/project/home.png" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.editProject && (
          <div className="modale_animation">
            <button
              type="button"
              id="projectListing"
              style={{ display: "none" }}
              data-toggle="modal"
              data-target="#projectListingModal"
            >
              Open Modal
            </button>

            <div
              className="modal fade projectListingModal"
              id="projectListingModal"
              role="dialog"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="projectListingModalBody text-center">
                      <div className="wrapper">
                        {/* <img src={`https://d3rc7k2n78msdl.cloudfront.net/project/${this.state.activeProject}`} /> */}

                        <div className="content">
                          <div className="inner">
                            <div className="updateProject have_not_box">
                              <div className="project_file_save" style={{top: '20px'}}>
                                <h2>New Project Name</h2>
                              </div>
                              <div className="saved_save_popup" style={{top: '90px'}}>
                                <div className="type_name">
                                  <img
                                    src="https://d3rc7k2n78msdl.cloudfront.net/input_background.png"
                                    alt="image"
                                  />
                                  <div className="typing_input_name">
                                    <input
                                      type="text"
                                      name="name"
                                      placeholder="File Name"
                                      value={this.state.blankProjectName}
                                      ref="projectName"
                                      
                                      onChange={(e) =>
                                        this.setState({
                                          blankProjectName: e.target.value,
                                        })
                                      }
                                    />
                                    {this.state.hasError && (
                                      <p className="errorMsg blankProjectName">
                                        * This is a required field.
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="yes_save">
                                  <img
                                    src="https://d3rc7k2n78msdl.cloudfront.net/yes_sve.png"
                                    onClick={(e) => this.renameProject()}
                                  />
                                </div>
                                <div className="yes_save">
                                  <img
                                    src="https://d3rc7k2n78msdl.cloudfront.net/no_dont_save.png"
                                    onClick={(e) => this.closeModel()}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  canvas: state.canvas,
  user: state.user,
});
const mapDispatchToProps = (dispatch) => ({
  getProjectsInVault: bindActionCreators(getProjectsInVault, dispatch),
  deleteProjectInVault: bindActionCreators(deleteProjectInVault, dispatch),
  renameProject: bindActionCreators(renameProject, dispatch),
  swal: bindActionCreators(swal.showAlert, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(MyProjects);
