// FontPreloader.js
import { useEffect } from "react";
import FontFaceObserver from "fontfaceobserver";

const FontPreloader = ({ fonts, onFontsLoaded }) => {
  useEffect(() => {
    const fontObservers = fonts.map(font => {
      const fontFace = new FontFaceObserver(font);
      return fontFace.load();
    });

    Promise.all(fontObservers)
      .then(() => {
        if (onFontsLoaded) onFontsLoaded();
      })
      .catch(err => {
        console.error("Font loading failed", err);
      });
  }, [fonts, onFontsLoaded]);

  return null;
};

export default FontPreloader;
