export default {
  Circle: "circle",
  Line: "line",
  Arrow: "arrow",
  Pencil: "pencil",
  Rectangle: "rectangle",
  Triangle: "triangle",
  Select: "select",
  Pan: "pan",
  Eraser: "eraser",
  Trace: "trace",
  PaintBucket: "paintBucket",
  Ellipse: "ellipse",
  Transform: "transform",
  Eyedropper: "eyedropper",
  Group: "groupSelection",
  Ungroup: "ungroupSelection",
  Text: 'text'
};
