import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getProjectsInVault, deleteProjectInVault, renameProject, getkeyFrameData } from "../redux/user/actions";
import classnames from "classnames";
import { swal } from "react-redux-sweetalert2";
import { ClipLoader } from "halogenium";
import CommonMethod from "../utilities/CommonMethod";

class ProjectListingModal extends Component {
    constructor(props) {
		super(props);
		this.state = {
			projectImage: {
				wiggledoodle: 'icon-1.png',
				flipbook: 'icon-2.png',
				'advanced-doodle': 'icon-3.png'
			},
			projectData: [],
			showLoader: true,
			activeId: '',
			blankProjectName: "",
			editProject: false,
			hasError: false
		}
	}

	componentDidMount() {
		let projectObj = {
			userId: this.props.user.userDetails.id,
			category: this.props.category
		};
		this.props.getProjectsInVault(projectObj, res => {
			this.setState({
				projectData: res,
				showLoader: false
			});
		});
	}

	activeProject = (id, action = '') => {
		const data = {
			activeId: id
		};

		// reset edit/rename popup when we click open
		if(action) data.editProject = false; 
		this.setState({...data});
	}

	loadProject = (id) => {
		this.props.loadProjectFromPopup(id);
	}

	deleteProject = (id) => {
		if(this.props.canvas.currentProject._id == id) {
			this.props.swal({
				title: "Info",
				text: "Can't delete current loaded project.",
				type: "info",
				confirmAlert: () => false
			  });
			return;
		}
		this.props.swal({
            title: "Info!",
            text: "Are you sure you want to delete this?",
            type: "info",
            showConfirmButton: true,
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmAlert: () =>  {
				this.props.deleteProjectInVault({_id: id}, res => {
					let allProjects = [...this.state.projectData];
					const index = 	allProjects.findIndex(e => e._id == id);
					if(index > -1) {
						allProjects.splice(index, 1);
						this.setState({
							projectData: allProjects
						});
					}
				})
            },
            cancelCallback: () => {}
        });
	}
	showEditProjectUI = (id) => {
		let allProjects = [...this.state.projectData];
		const index = allProjects.findIndex(e => e._id == id);
		this.setState({
			activeId: id,
			editProject: true,
			blankProjectName: allProjects[index].name,
			hasError: false
		});
	}

	renameProject = () => {
		const newProjectName = this.state.blankProjectName.trim();
		if(!newProjectName) {
			this.setState({hasError: true});
			return;
		} else {
			this.setState({hasError: false});
		}
		let allProjects = [...this.state.projectData];
		const index = 	allProjects.findIndex(e => e._id == this.state.activeId);
		if(index > -1){
			const currentProject = allProjects[index];
			if(currentProject.name === newProjectName) {
				this.setState({editProject: false});
			} else {
				const payload = {
					_id: currentProject._id,
					name: newProjectName,
					userId: this.props.user.userDetails.id,
					category: this.props.category
				};
				this.props.renameProject(payload, res => {
					allProjects[index].name = res.result.project.name;
					this.setState({
						projectData: allProjects,
						editProject: false
					});
				});
				//Todo update project name in store
			}
		}
	}

	addOrUpdateProject = (type, val) => {
		// const background = JSON.parse(res.data).length ? JSON.parse(res.data)[0]['keyframesBase64'] : '';
		// let allProjects = [...this.state.projectData];
		// const index = 	allProjects.findIndex(e => e._id == res._id);
		// if(index > -1) {
		// 	allProjects[index].keyframesBase64 = background;
		// } else {
		// 	allProjects = [...allProjects, {
		// 		_id: res._id,
		// 		keyframesBase64: background,
		// 		name: res.name
		// 	}];
		// }
		// this.setState({
		// 	projectData: allProjects
		// });


		this.props.getkeyFrameData(val, res => {
			const background = res.length ? res[0]['keyframesBase64'] : '';
			let allProjects = [...this.state.projectData];
			const index = 	allProjects.findIndex(e => e._id == val._id);

			if(index > -1) {
				allProjects[index].keyframesBase64 = background;
			} else {
				allProjects = [...allProjects, {
					_id: val._id,
					keyframesBase64: background,
					name: val.name
				}];
			}
			this.setState({
				projectData: allProjects

			});
		});
	}

	gotoProjectVault = () => {
		this.props.purgeCanvasAndGoToProjectVault();
		
	}

	showLoader = () => {
		return(
			<div className="projectVaultLoader">
				<ClipLoader color="#33A5C9" size="22px" margin="4px" />
			</div>
		)
	}

    render() {
		return (
            <div className="modale_animation">
				<button
					type="button"
					id="projectListing"
					style={{ display: "none" }}
					data-toggle="modal"
					data-target="#projectListingModal"
				>
					Open Modal
				</button>

				<div className="modal fade projectListingModal" id="projectListingModal" role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
							>
								&times;
							</button>
						</div>
						<div className="modal-content">
							<div className="modal-body">
								<div className="projectListingModalBody text-center">
									<div className="wrapper">
										<img src={`https://d3rc7k2n78msdl.cloudfront.net/project/${this.state.projectImage[this.props.category]}`} />
										{
											!this.state.projectData.length && !this.state.showLoader &&
											<div className="noContentFound alert alert-info">
												No Saved Projects Found
											</div>
										}
										{
											(this.state.projectData.length || this.state.showLoader) &&
												<div className="content">
												<div className="inner">
													{
														this.state.editProject &&
														<div className="updateProject have_not_box">
															<div className="project_file_save">
																<h2>New Project Name</h2>
															</div>
															<div className="saved_save_popup">
																<div className="type_name">
																	<img src="https://d3rc7k2n78msdl.cloudfront.net/input_background.png" alt="image" />
																	<div className="typing_input_name">
																	<input type="text" name="name" placeholder="File Name"
																		value={this.state.blankProjectName} ref="projectName"
																		onChange={(e) => this.setState({ blankProjectName: e.target.value })}
																		
																	/>
																	{
																		this.state.hasError &&
																		<p className="errorMsg blankProjectName">
																		* This is a required field.
																		</p>
																	}
																	</div>
																</div>
																<div className="yes_save">
																	<img
																	src="https://d3rc7k2n78msdl.cloudfront.net/yes_sve.png"
																	onClick={e => this.renameProject()}
																	/>
																</div>
																<div className="yes_save">
																	<img
																	src="https://d3rc7k2n78msdl.cloudfront.net/no_dont_save.png"
																	onClick={e => this.setState({editProject: false})}
																	/>
																</div>
															</div>
														</div>
													}
													{
														!this.state.editProject &&
														<div className="bault_small_box">
															<img src="https://d3rc7k2n78msdl.cloudfront.net/project/small_box.png" /> 
															<div className="art_list">
																<ul>
																{this.state.showLoader && this.showLoader()}
																{
																	this.state.projectData.map((v, i) => {
																		return (
																			<li key={i}>
																				<div
																				className={classnames({
																					single: true,
																					art_inner_col: true,
																					active: this.state.activeId == v._id
																				})} 
																				onClick={() => this.activeProject(v._id)} 
																				onDoubleClick={() => this.loadProject(v._id)} 
																				>
																					{/* <div className="left"> */}
																						<span className="image">
																							<img src={v.keyframesBase64 ? v.keyframesBase64 : 'https://d3rc7k2n78msdl.cloudfront.net/no-preview.png'} />
																						</span>
																						<span className="text">
																							{v.name} 
																						</span>
																					{/* </div> */}
																					{/* <div className="right"> */}
																						<span className="actions">
																							<i className="fa fa-pencil-square-o" aria-hidden="true" onClick={() => this.showEditProjectUI(v._id)}></i>
																							<i className={classnames({
																								'fa fa-trash': true,
																								disable: this.props.canvas.currentProject._id === v._id
																							})}
																							aria-hidden="true" onClick={() => this.deleteProject(v._id)}></i>
																						</span>																					
																					{/* </div> */}
																				</div>
																			</li>
																		)
																	})
																}
																</ul>
															</div>
														</div>
													}
												</div>
											</div>
										}
										{
											!this.state.editProject &&
											<img className="openProjectVault" src='https://d3rc7k2n78msdl.cloudfront.net/myproject/open_projectvault.png' onClick={() => this.gotoProjectVault()} />
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        )
    }
}

const mapStateToProps = state => ({
	canvas: state.canvas,
	user: state.user
});

const mapDispatchToProps = dispatch => ({
	getProjectsInVault: bindActionCreators(getProjectsInVault, dispatch),
	deleteProjectInVault: bindActionCreators(deleteProjectInVault, dispatch),
	renameProject: bindActionCreators(renameProject, dispatch),
	getkeyFrameData: bindActionCreators(getkeyFrameData, dispatch),
	swal: bindActionCreators(swal.showAlert, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(ProjectListingModal);