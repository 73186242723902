import React from "react";
import classnames from "classnames";
import $ from "jquery";

const Topbar = props => {
  return (
    <div className="wiggle_topbar  advanced_sec">
      <div className="top_bar_logo">
        <img src="https://d3rc7k2n78msdl.cloudfront.net/flipbook/logo.png" />
      </div>
      <div className="mid_bar_icons">
        <div className="bar_left_icons">
          <div
            className="bar_icons_one home_icon"
            title="Home"
            onClick={() => props.newProjectOrGoToHomeOrAdvanced("goToHome")}
          >
            <img
              className="active_image normal"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/home/with_text/home_on.png"
            />
            <img
              className="disabled"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/home/with_text/home_disabled.png"
            />
            <img
              className="hover"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/home/with_text/home_over.gif"
            />
            <img
              className="on_click"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/home/with_text/home_click.png"
            />
          </div>

          <div
            className="bar_icons_one new_icon"
            title="New"
            onClick={() => props.newProjectOrGoToHomeOrAdvanced()}
          >
            <img
              className="active_image normal"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/new/with_text/new_on.png"
            />
            <img
              className="disabled"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/new/with_text/new_disabled.png"
            />
            <img
              className="hover"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/new/with_text/new_over.gif"
            />
            <img
              className="on_click"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/new/with_text/new_click.png"
            />
          </div>
          <div
            className="bar_icons_one open_icon"
            title="Open"
            onClick={() => props.openProjectsVault()}
          >
            <img
              className="active_image normal"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/open/with_text/open_on.png"
            />
            <img
              className="disabled"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/open/with_text/open_disabled.png"
            />
            <img
              className="hover"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/open/with_text/open_over.gif"
            />
            <img
              className="on_click"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/open/with_text/open_click.png"
            />
          </div>
          <div
            className="bar_icons_one save_icon"
            title="Save"
            onClick={() => props.saveOrUpdateProjectFromSaveIcon()}
          >
            <img
              className="active_image normal"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/save/with_text/save_on.png"
            />
            <img
              className="disabled"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/save/with_text/save_disabled.png"
            />
            <img
              className="hover"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/save/with_text/save_over.gif"
            />
            <img
              className="on_click"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/save/with_text/save_click.png"
            />
          </div>
          <div
            className="bar_icons_one share_icon"
            data-toggle="modal"
            data-target="#share_modal"
            title="Export Movie"
          >
            <img
              className="active_image normal"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/share/with_text/share_on.png"
            />
            <img
              className="disabled"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/share/with_text/share_disabled.png"
            />
            <img
              className="hover"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/share/with_text/share_over.gif"
            />
            <img
              className="on_click"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/share/with_text/share_click.png"
            />
          </div>
        </div>
        <div className="bars_mid_icons">
          <div
            className="bar_icons_one cut_icon"
            title="Cut"
            onClick={
              props.isObjectSelected
                ? () => props.cut()
                : () => console.log("no object selected")
            }
          >
            <img
              className={classnames({
                active_image: props.isObjectSelected,
                normal: true
              })}
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/cut/with_text/cut_on.png"
            />
            <img
              className={classnames({
                active_image: !props.isObjectSelected,
                disabled: true
              })}
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/cut/with_text/cut_disabled.png"
            />
            <img
              className="hover"
              src={
                !props.isObjectSelected
                  ? "https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/cut/with_text/cut_disabled.png"
                  : "https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/cut/with_text/cut_over.gif"
              }
              style={!props.isObjectSelected ? { opacity: 0.5 } : {}}
            />
            <img
              className="on_click"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/cut/with_text/cut_click.png"
            />
          </div>
          <div
            className="bar_icons_one copy_icon"
            title="Copy"
            onClick={
              props.isObjectSelected
                ? () => props.copy()
                : () => console.log("no object selected")
            }
          >
            <img
              className={classnames({
                active_image: props.isObjectSelected,
                normal: true
              })}
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/copy/with_text/copy_on.png"
            />
            <img
              className={classnames({
                active_image: !props.isObjectSelected,
                disabled: true
              })}
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/copy/with_text/copy_disabled.png"
            />
            <img
              className="hover"
              src={
                !props.isObjectSelected
                  ? "https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/copy/with_text/copy_disabled.png"
                  : "https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/copy/with_text/copy_over.gif"
              }
              style={!props.isObjectSelected ? { opacity: 0.5 } : {}}
            />
            <img
              className="on_click"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/copy/with_text/copy_click.png"
            />
          </div>
          <div
            className="bar_icons_one paste_icon"
            title="Paste"
            onClick={
              props.isPasteEnabled
                ? () => props.paste()
                : () => console.log("paste is not enabled yet.")
            }
          >
            <img
              className={classnames({
                active_image: props.isPasteEnabled,
                normal: true
              })}
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/paste/with_text/paste_on.png"
            />
            <img
              className={classnames({
                active_image: !props.isPasteEnabled,
                disabled: true
              })}
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/paste/with_text/paste_disabled.png"
            />
            <img
              className="hover"
              src={
                !props.isPasteEnabled
                  ? "https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/paste/with_text/paste_disabled.png"
                  : "https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/paste/with_text/paste_over.gif"
              }
              style={!props.isPasteEnabled ? { opacity: 0.5 } : {}}
            />
            <img
              className="on_click"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/copy/with_text/copy_click.png"
            />
          </div>
          <div
            className="bar_icons_one undo_icon"
            title="Undo"
            onClick={
              props.checkIfUndoIsDisabled()
                ? () => props.undoAction()
                : () => console.log("cannot undo more..")
            }
          >
            <img
              className={classnames({
                active_image: props.checkIfUndoIsDisabled(),
                normal: true
              })}
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/undo/with_text/undo_on.png"
            />
            <img
              className={classnames({
                active_image: !props.checkIfUndoIsDisabled(),
                disabled: true
              })}
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/undo/undo_disabled.png"
            />
            <img
              className="hover"
              src={
                !props.checkIfUndoIsDisabled()
                  ? "https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/undo/undo_disabled.png"
                  : "https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/undo/with_text/undo_over.gif"
              }
            />
            <img
              className="on_click"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/undo/with_text/undo_click.png"
            />
          </div>
          <div
            className="bar_icons_one delete_icon"
            title="Delete"
            onClick={() => props.removeSelectedObject()}
          >
            <img
              className={classnames({
                active_image: props.isObjectSelected,
                normal: true
              })}
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/delete/with_text/delete_on.png"
            />
            <img
              className={classnames({
                active_image: !props.isObjectSelected,
                disabled: true
              })}
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/delete/with_text/delete_disabled.png"
            />
            <img
              className="hover"
              src={
                !props.isObjectSelected
                  ? "https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/delete/with_text/delete_disabled.png"
                  : "https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/delete/with_text/delete_over.gif"
              }
              style={!props.isObjectSelected ? { opacity: 0.5 } : {}}
            />
            <img
              className="on_click"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/delete/with_text/delete_click.png"
            />
          </div>
        </div>

        <div className="sec_last_bar_icons">
        {/* <div
            className="bar_icons_one zoom_fit_icon"
            title="Scale to fit"
            onClick={(e) => {
              const isActiveImage = e.target.classList.contains('hover');
              if (isActiveImage && props.isGroupAllEnabled && props.selectedTool == 'groupSelection') {
                if(props.isObjectSelected){

                  props.scaleToFit("scale");
                }else{
                  alert("Information: Please select all the Objects before Scale to fit")
                }

              }else{
                alert("Information: Active Group Icon before Scale to fit.")
                
              }
            }}
          >
            
            <img
              className={classnames({
                active_image: props.isGroupAllEnabled,
                normal: true
              })}
              src="https://d3rc7k2n78msdl.cloudfront.net/advanced/scale-to-fit.png"
            />
            <img
              className={classnames({
                active_image: !props.isGroupAllEnabled,
                disabled: true
              })}
              src="https://d3rc7k2n78msdl.cloudfront.net/advanced/scale-to-fit.png"
            />
            <img
              className="hover"
              src={
                !props.isGroupAllEnabled
                  ? "https://d3rc7k2n78msdl.cloudfront.net/advanced/scale-to-fit.png"
                  : "https://d3rc7k2n78msdl.cloudfront.net/advanced/scale-to-fit.png"
              }
              style={!props.isGroupAllEnabled ? { opacity: 0.5 } : {}}
            />
            <img
              className="on_click"
              src="https://d3rc7k2n78msdl.cloudfront.net/advanced/scale-to-fit.png"
            />
          </div> */}




          <div className="bar_icons_one pressure_icon" title="Drawing Pressure">
            <img
              className="active_image normal"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/pressure/with_text/pressure_on.png"
            />
            <img
              className="disabled"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/pressure/with_text/pressure_disabled.png"
            />
            <img
              className="hover"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/pressure/with_text/pressure_over.gif"
            />
            <div className="bottom_sec">
              <div className="brush_board">
                <div className="slidecontainer">
                  <div className="top_dot">
                    <span
                      style={{
                        height: "18px",
                        width: "18px",
                        display: "flex",
                        backgroundColor: props.selectedBrushColor,
                        borderRadius: "50%",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto",
                        opacity: props.pressureOpacity
                      }}
                    />
                  </div>
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    className="slider"
                    id="myRange"
                    value={props.pressureOpacity}
                    onChange={e => props.setPressureOpacity(e)}
                  />
                </div>
              </div>
            </div>
            <img
              className="on_click"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/pressure/with_text/pressure_click.png"
            />
          </div>
          <div
            className="bar_icons_one behind_icon flip_behind_icon"
            onClick={() => {
              props.enableDisableSendToBack(!props.sendToBackEnabled);
            }}
            title={
              props.sendToBackEnabled
                ? "Turn Draw Behind Off"
                : "Turn Draw Behind On"
            }
          >
            <img
              className={classnames({
                active_image: props.sendToBackEnabled ? false : true,
                normal: true
              })}
              src="https://d3rc7k2n78msdl.cloudfront.net/advanced/behind-off.png"
            />
            <img
              className="disabled"
              src="https://d3rc7k2n78msdl.cloudfront.net/advanced/behind-off.png"
            />
            <img
              className="hover"
              src={
                props.sendToBackEnabled
                  ? "https://d3rc7k2n78msdl.cloudfront.net/advanced/behind-on.png"
                  : "https://d3rc7k2n78msdl.cloudfront.net/advanced/behind-off.png"
              }
            />
            <img
              className={classnames({
                active_image: props.sendToBackEnabled ? true : false,
                on_click: true
              })}
              src="https://d3rc7k2n78msdl.cloudfront.net/advanced/behind-on.png"
            />
          </div>
          <div
            className="bar_icons_one trace_icon"
            title={props.traceEnabled ? "Turn Trace Off" : "Turn Trace On"}
            onClick={() => {
              props.enableDisableTrace(!props.traceEnabled);
            }}
          >
            <img
              className={classnames({
                active_image: props.traceEnabled ? false : true,
                normal: true
              })}
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/trace/with_text/trace_on.png"
            />
            <img
              className="disabled"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/trace/with_text/trace_disabled.png"
            />
            <img
              className="hover"
              src={
                props.traceEnabled
                  ? "https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/trace/with_text/trace_click.png"
                  : "https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/trace/with_text/trace_over.gif"
              }
            />
            <img
              className={classnames({
                active_image: props.traceEnabled ? true : false,
                on_click: true
              })}
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/trace/with_text/trace_click.png"
            />
          </div>
        </div>

        <div className="last_bar_icons">
          <div
            title="Import Image"
            className="bar_icons_one import_icon"
            onClick={() => $("#importImg").trigger("click")}
          >
            <input
              type="file"
              id="importImg"
              onChange={e => props.importImg(e)}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0
              }}
            />
            <img
              className="active_image normal"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/import/with_text/import_on.png"
            />
            <img
              className="disabled"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/import/with_text/import_disabled.png"
            />
            <img
              className="hover"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/import/with_text/import_over.gif"
            />
            <img
              className="on_click"
              src="https://d3rc7k2n78msdl.cloudfront.net/wiggledoodle-ish/import/with_text/import_click.png"
            />
          </div>
        </div>
      </div>

      <div
        className="right_bar_icons"
        title="Go To Next Level"
        onClick={() => props.newProjectOrGoToHomeOrAdvanced("goToAdvanced")}
      >
        <img
          className="right_normal_image"
          src="https://d3rc7k2n78msdl.cloudfront.net/advanced/advancedish-bw.png"
        />
        <img
          className="right_hover_image"
          src="https://d3rc7k2n78msdl.cloudfront.net/advanced/advancedish-color.png"
        />
      </div>
      <div id="share_modal" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="heading_pop">
                <h2>Animation-ish</h2>
              </div>
              <div className="share_animation">
                <div className="border_all_time">
                  <img src="https://d3rc7k2n78msdl.cloudfront.net/share/background.png" />
                  <div className="share_overlay">
                    <div className="top_share_heading">
                      <img src="https://d3rc7k2n78msdl.cloudfront.net/share/heading.png" />
                    </div>
                    <div className="mid_button_share">
                      <ul>
                        <li onClick={() => props.saveVideo("mov")}>
                          <img src="https://d3rc7k2n78msdl.cloudfront.net/share/button2.png" />
                        </li>
                        <li onClick={() => props.saveVideo("mpeg")}>
                          <img src="https://d3rc7k2n78msdl.cloudfront.net/share/MPEG.png" />
                        </li>
                        <li onClick={() => props.saveVideo("mp4")}>
                          <img src="https://d3rc7k2n78msdl.cloudfront.net/share/MP4.png" />
                        </li>
                        <li onClick={() => props.saveAsImgSequence()}>
                          <img src="https://d3rc7k2n78msdl.cloudfront.net/share/button5.png" />
                        </li>
                        <li onClick={() => props.saveVideo("gif")}>
                          <img src="https://d3rc7k2n78msdl.cloudfront.net/share/gif.png" />
                        </li>
                        <li className="" onClick={() => props.exportState()}>
                          <img src="https://d3rc7k2n78msdl.cloudfront.net/share/export.png" />
                        </li>
                      </ul>
                    </div>
                    <div className="bottom_button">
                      <div className="bottom_button_div">
                        <img
                          src="https://d3rc7k2n78msdl.cloudfront.net/share/button6.png"
                          data-dismiss="modal"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
