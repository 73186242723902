/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";
	
class Ungroup extends FabricCanvasTool {
	configureCanvas(props) {
		let canvas = this._canvas;
		canvas.isDrawingMode = false;
		canvas.selection = true;
		canvas.forEachObject(o => {
			o.selectable = o.evented = o.hasControls = o.hasBorders = true;
			const activeObject = canvas.getActiveObject();
			if (!activeObject) {
				return;
			}
			if (activeObject.type == 'group') {
				const activeSelection = activeObject.toActiveSelection();

				// Enable caching for each object in the active selection for performance
				activeSelection.getObjects().forEach((obj) => {
					obj.set("objectCaching", true);
				});

				// Ensure the canvas updates visually
				canvas.requestRenderAll();
			}

		});

	}
}

export default Ungroup;
