/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";
import { linearDistance } from "./utils";

const fabric = require("fabric").fabric;

class Text extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = false;
    canvas.selection = false;

    canvas.forEachObject((o) => {
      o.selectable = false;
      o.evented = false;
      o.hasControls = false;
      o.hasBorders = false;
    });
    this._width = props.lineWidth || 1; // Default to 2 if not provided
    this._color = props.lineColor || "#000000"// Default to black if not provided
    this._fill =
      props.fillColor && props.fillColor.toLowerCase() === "transparent"
        ? "#000000"
        : props.fillColor || "#000000";
    this._fontSize = props.fontSize || 34; // Set font size to 20 if not provided
    this._fontFamily = props.fontFamily || "Arial"; // Set font family to Arial if not provided
    this._fontWeight = props.fontWeight || "normal"; // Set font weight to normal if not provided
  }

 
  
  doMouseDown(o) {
    let canvas = this._canvas;
    if (this.textObject) return; // If a text object already exists, return early

    this.isDown = true;
    let pointer = canvas.getPointer(o.e);
    [this.startX, this.startY] = [pointer.x, pointer.y];

    // Create a new fabric.Text instance with controlled properties
    this.textObject = new fabric.Textbox(" ", {
      left: this.startX,
      top: this.startY,
      originX: "center",
      originY: "center",
      fontSize: this._fontSize,
      fontFamily: this._fontFamily,
      fontWeight: this._fontWeight,
      fill: this._color, // This sets the fill color of the text
      strokeWidth: 0,
      stroke: null,
      selectable: true,
      evented: true,
      width: 150, // Limit the width to 150 pixels for better control
      textAlign: "left", // Align text to the left
      editable: true,
    });

    canvas.add(this.textObject);
    canvas.setActiveObject(this.textObject);
    this.textObject.enterEditing(); // Automatically enter text editing mode
    canvas.renderAll();
  }

  doMouseUp(o) {
    this.isDown = false;
    this.textObject = null; // Reset the text object so a new one can be added on the next mouse down
  }


}

export default Text;
