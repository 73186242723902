import React, { Component } from "react";

class FontModal extends Component {
  handleFontSelection = (font) => {
    this.props.onFontSelect(font);
  };

  handleFontConfirm = () => {
    this.props.onFontConfirm();
    // Close the modal after confirming
    window.$('#fontModal').modal('hide');
  };

  componentDidMount() {
    // Initialize the Bootstrap modal
    window.$('#fontModal').modal({ show: false });
  }

  render() {
    const fontOptions = [
      { name: "Raleway", style: "sans-serif" },
      { name: "Shadows Into Light Two", style: "handwriting" },
      { name: "Amatic SC", style: "caps" },
      { name: "Alike Angular", style: "serif" },
    ];

    return (
      <div
        className="modal fade fontsModal"
        id="fontModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="fontModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="fontModalLabel">
                Select Font Family
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                {fontOptions.map((font) => (
                  <div className="form-check" key={font.name}>
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="fontFamily"
                        id={font.name}
                        value={font.name}
                        checked={this.props.selectedFont === font.name}
                        onChange={() => this.handleFontSelection(font.name)}
                      />
                      <span style={{ fontFamily: font.name, marginLeft: '5px' }}>{font.name}</span>
                    </label>
                  </div>
                ))}
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.handleFontConfirm}
              >
                Confirm Font
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FontModal;
