import React,{useState}  from "react";
import ShapeSelectorModal from "../../components/ShapeSelectorModal";
import classnames from "classnames";


const LeftTools = props => {
 const [groupImg, setGroupImg]= useState(true);
  const [showShapeModal, setShowShapeModal] = useState(false);
  const [selectedShape, setSelectedShape] = useState(null);
  const { selectedTool, setSelectedTool } = props;
  
  
  const handleShapeSelection = (shape) => {
    props.setSelectedTool(shape);
    setSelectedShape(shape);
    setShowShapeModal(false);
  };
  const handleImageClick = () => {
    // Set the selected tool to "text"
    props.setSelectedTool("text");

    // Trigger the modal
    props.openFontModal();
  };
  return (
    <div className="wiggle_doddle_main_mid_left col-sm-2 flipbook_left_tool ">
      <div className="tool_bar">
  <img src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/tools-outline-long-vertical.svg" />
  <div className="overlay_classes">
    <ul>
      <li
        className={props.selectedTool === "select" ? "active_tools" : ""}
        onClick={() => props.setSelectedTool("select")}
        title="Select"
      >
        <img src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/pointer.png" />
      </li>

      <li
        className={props.selectedTool === "pencil" ? "active_tools" : ""}
        title="Brush"
        onClick={() => props.setSelectedTool("pencil")}
      >
        <img src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/brush.png" />
      </li>

      <li
        className={props.selectedTool === "paintBucket" ? "active_tools" : ""}
        title="Paint"
        onClick={() => props.setSelectedTool("paintBucket")}
      >
        <img src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/color.png" />
      </li>

      {/* <li
        title="Undo"
        onClick={
          props.checkIfUndoIsDisabled()
            ? () => props.undoAction()
            : () => console.log("cannot undo more..")
        }
      >
        <img
          className={classnames({
            active_image: props.checkIfUndoIsDisabled(),
            disabled: !props.checkIfUndoIsDisabled(),
          })}
          src="https://d3rc7k2n78msdl.cloudfront.net/advanced/UndoToolIcon.svg"
        />
      </li> */}

      <li
        className={props.selectedTool === "eraser" ? "active_tools" : ""}
        title="Eraser"
        onClick={() => props.setSelectedTool("eraser")}
      >
        <img src="https://d3rc7k2n78msdl.cloudfront.net/wiggle_doddle_main/rubber.png" />
      </li>

      <li
        className={
          props.selectedTool === "groupSelection" ||
          props.selectedTool === "ungroupSelection"
            ? "active_tools"
            : ""
        }
        onClick={() => {
          if (props.selectedTool === "groupSelection") {
            props.checkIfGroupAllEnabled(groupImg);
          }
          setGroupImg(!groupImg);
          props.setSelectedTool(
            props.selectedTool === "groupSelection" ? "ungroupSelection" : "groupSelection"
          );
        }}
        title={
          props.selectedTool === "groupSelection"
            ? "Group Selection"
            : "Ungroup Selection"
        }
      >
        <img
          src={
            groupImg
              ? props.selectedTool === "groupSelection"
                ? "https://d3rc7k2n78msdl.cloudfront.net/advanced/ungroup.png"
                : "https://d3rc7k2n78msdl.cloudfront.net/advanced/group.png"
              : props.selectedTool === "ungroupSelection"
              ? "https://d3rc7k2n78msdl.cloudfront.net/advanced/group.png"
              : "https://d3rc7k2n78msdl.cloudfront.net/advanced/ungroup.png"
          }
        />
      </li>

      <li
        id="flip_Shapes"
        title="Shapes"
        className={
          props.selectedTool === "circle" ||
          props.selectedTool === "rectangle" ||
          props.selectedTool === "line" ||
          props.selectedTool === "triangle"
            ? "active_tools"
            : ""
        }
        onClick={() => setShowShapeModal(true)}
      >
        <img
          src="https://d3rc7k2n78msdl.cloudfront.net/advanced/shapes.png"
          alt="Shapes"
        />
      </li>

      {/* <li
        title="Text"
        className={props.selectedTool === "text" ? "active_tools" : ""}
      >
        <img
          src="https://d3rc7k2n78msdl.cloudfront.net/advanced/TextToolIcon.svg"
        onClick={handleImageClick}
          alt="Text Tool"
        />
      </li> */}
    </ul>
  </div>
</div>

      
      {/*<div className="inspire_me_image">
        <img
          className="normal_show"
          src="images/wiggle_doddle_main/inspireme_small.png"
        />
        <img
          style={{ display: "none" }}
          className="hover_show"
          src="images/wiggle_doddle_main/inspireme_small_over.gif"
        />
        <img
          style={{ display: "none" }}
          className="click_show"
          src="images/wiggle_doddle_main/inspireme_small_click.png"
        />
      </div>*/}
      <ShapeSelectorModal
        showShapeModal={showShapeModal}
        setShowShapeModal={setShowShapeModal}
        handleShapeSelection={handleShapeSelection}
        selectedShape={selectedShape}
      />

    </div>
  );
};

export default LeftTools;
