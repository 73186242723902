import FabricCanvasTool from "./fabrictool";
const fabric = require("fabric").fabric;

class OptimizedPencil extends FabricCanvasTool {
    constructor(canvas) {
        super(canvas);
        this._isDrawing = false; // Track drawing state
        this._pathCreatedListenerAttached = false; // Ensure path listener is attached only once
    }

    configureCanvas(props) {
        // Activate drawing mode
        this._canvas.isDrawingMode = true;

        // Use PencilBrush for drawing
        this._canvas.freeDrawingBrush =  new fabric.PencilBrush(this._canvas);

        let color;

		if(props.lineColor.includes('rgba')){
			let values = props.lineColor
				.replace(/rgba?\(/, "")
				.replace(/\)/, "")
				.replace(/[\s+]/g, "")
				.split(",");
			
			if(values[3] == 1){
				color = `rgba(${values[0]},${values[1]},${values[2]},${props.opacity})`;
			}
			else {
				color = props.lineColor;
			}
		}
		else {
			let rgb_color = props.hexToRgb(props.lineColor);
	
			if(rgb_color){
				color = `rgba(${rgb_color.r},${rgb_color.g},${rgb_color.b},${props.opacity})`;
			}
			else {
				color = props.lineColor;
			}	
		}


         this._canvas.freeDrawingBrush.color = color;
        this._canvas.freeDrawingBrush.width = props.lineWidth;

        // Attach event listeners for mouse actions
        this.attachMouseListeners();

        // Attach path:created listener for setting composite operation
        if (!this._pathCreatedListenerAttached) {
            this._canvas.on("path:created", (opt) => {
                requestAnimationFrame(() => {
                    opt.path.globalCompositeOperation = props.selectedSpace;
                });
            });
            this._pathCreatedListenerAttached = true;
        }

        // Optimize canvas rendering settings
        this._canvas.preserveObjectStacking = false;
        this._canvas.enableRetinaScaling = false;
        this._canvas.skipOffscreen = true;
    }

    attachMouseListeners() {
        const canvas = this._canvas;

        // Handle mouse down
        canvas.on("mouse:down", () => {
            this._isDrawing = true; // Set drawing state
        });

        // Handle mouse move with throttling
        canvas.on("mouse:move", () => {
            if (this._isDrawing) {
                requestAnimationFrame(() => {
                    canvas.requestRenderAll(); // Optimize rendering
                });
            }
        });

        // Handle mouse up
        canvas.on("mouse:up", () => {
            this._isDrawing = false; // Reset drawing state
            canvas.requestRenderAll(); // Ensure final rendering
        });
    }

    doMouseDown() {}
}

export default OptimizedPencil;
