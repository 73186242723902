/*eslint no-unused-vars: 0*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import History from "./history";
import { uuid4 } from "./utils";
import Select from "./select";
import Pencil from "./pencil";
import Group from "./group";
import Ungroup from "./ungroup";
import Text  from "./text";
import Line from "./line";
import Arrow from "./arrow";
import Rectangle from "./rectangle";
import Triangle from "./triangle";
import Circle from "./circle";
import Pan from "./pan";
import Tool from "./tools";
import Swal from "sweetalert2";

import Eraser from "./eraserV2";
// import Eraser from "./eraser";
import Trace from "./trace";
import PaintBucket from "./paintBucket";
import Ellipse from "./ellipse";
import Transform from "./transform";
import Draggable from "react-draggable";
import analyze from "rgbaster";
import $ from "jquery";
import classnames from "classnames";

const fabric = require("fabric").fabric;
var SCALE_FACTOR = 1.3;
var zoomMax = 23;
/**
 * Sketch Tool based on FabricJS for React Applications
 */
class SketchField extends PureComponent {
  static propTypes = {
    // the color of the line
    lineColor: PropTypes.string,
    // The width of the line
    lineWidth: PropTypes.number,
    // the fill color of the shape when applicable
    fillColor: PropTypes.string,
    // the background color of the sketch
    backgroundColor: PropTypes.string,
    // the opacity of the object
    opacity: PropTypes.number,
    // number of undo/redo steps to maintain
    undoSteps: PropTypes.number,
    // The tool to use, can be pencil, rectangle, circle, brush;
    tool: PropTypes.string,
    // image format when calling toDataURL
    imageFormat: PropTypes.string,
    // Sketch data for controlling sketch from
    // outside the component
    value: PropTypes.object,
    // Set to true if you wish to force load the given value, even if it is  the same
    forceValue: PropTypes.bool,
    // Specify some width correction which will be applied on auto resize
    widthCorrection: PropTypes.number,
    // Specify some height correction which will be applied on auto resize
    heightCorrection: PropTypes.number,
    // Specify action on change
    onChange: PropTypes.func,
    // Default initial value
    defaultValue: PropTypes.object,
    // Sketch width
    width: PropTypes.number,
    // Sketch height
    height: PropTypes.number,
    // Class name to pass to container div of canvas
    className: PropTypes.string,
    // Style options to pass to container div of canvas
    style: PropTypes.object,
  };
  
  static defaultProps = {
    lineColor: "black",
    lineWidth: 10,
    fillColor: "transparent",
    backgroundColor: "transparent",
    opacity: 1.0,
    undoSteps: 25,
    tool: Tool.Pencil,
    widthCorrection: 2,
    heightCorrection: 0,
    forceValue: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      parentWidth: 550,
      action: true,
      traceBackgroundImgBg: "",
      traceBackgroundImgFg: "",
      fontFamily: props.fontFamily
    };
    
    this.zoomHistory = [];
    this.animatedIndex = 1;

    this.updateAnimation = this.updateAnimation.bind(this);
  }

  _initTools = (fabricCanvas) => {
    this._tools = {};
    this._tools[Tool.Select] = new Select(fabricCanvas);
    this._tools[Tool.Group] = new Group(fabricCanvas);
    this._tools[Tool.Ungroup] = new Ungroup(fabricCanvas);
    this._tools[Tool.Text] = new Text(fabricCanvas);
    this._tools[Tool.Pencil] = new Pencil(fabricCanvas);
    this._tools[Tool.Line] = new Line(fabricCanvas);
    this._tools[Tool.Arrow] = new Arrow(fabricCanvas);
    this._tools[Tool.Rectangle] = new Rectangle(fabricCanvas);
    this._tools[Tool.Circle] = new Circle(fabricCanvas);
    this._tools[Tool.Triangle] = new Triangle(fabricCanvas);
    this._tools[Tool.Pan] = new Pan(fabricCanvas);
    this._tools[Tool.Eraser] = new Eraser(fabricCanvas);
    this._tools[Tool.Trace] = new Trace(fabricCanvas);
    this._tools[Tool.PaintBucket] = new PaintBucket(fabricCanvas);
    this._tools[Tool.Ellipse] = new Ellipse(fabricCanvas);
    this._tools[Tool.Transform] = new Transform(fabricCanvas);
  };

  /**
   * Enable touch Scrolling on Canvas
   */
  enableTouchScroll = () => {
    let canvas = this._fc;
    if (canvas.allowTouchScrolling) return;
    canvas.allowTouchScrolling = true;
  };

  /**
   * Disable touch Scrolling on Canvas
   */
  disableTouchScroll = () => {
    let canvas = this._fc;
    if (canvas.allowTouchScrolling) {
      canvas.allowTouchScrolling = false;
    }
  };

  /**
   * Add an image as object to the canvas
   *
   * @param dataUrl the image url or Data Url
   * @param options object to pass and change some options when loading image, the format of the object is:
   *
   * {
   *   left: <Number: distance from left of canvas>,
   *   top: <Number: distance from top of canvas>,
   *   scale: <Number: initial scale of image>
   * }
   */
  addImg = (dataUrl, options = {}) => {
    let canvas = this._fc;
    fabric.Image.fromURL(dataUrl, (oImg) => {
      let opts = {
        left: Math.random() * (canvas.getWidth() - oImg.width * 0.5),
        top: Math.random() * (canvas.getHeight() - oImg.height * 0.5),
        scale: 0.5,
      };
      Object.assign(opts, options);
      oImg.scale(opts.scale);

      oImg.set({
        left: opts.left,
        top: opts.top,
      });
      canvas.add(oImg);
      oImg.center();
    });
  };

  /**
   * Action when an object is added to the canvas
   */
  // _onObjectAdded = (e) => {
  //   let canvas = this._fc;
  //   if (!this.state.action) {
  //     this.setState({ action: true });
  //     return;
  //   }

  //   if (this.props.sendToBackEnabled) {
  //     setTimeout(() => {
  //       let canvas = this._fc;
  //       let arr = canvas.getObjects();
  //       canvas.sendToBack(arr[arr.length - 1]);
  //       canvas.renderAll();
  //     }, 15);
  //   }

  //   let obj = e.target;
  //   obj.__version = 1;
  //   // record current object state as json and save as originalState
  //   let objState = obj.toJSON();
  //   obj.__originalState = objState;
  //   let state = JSON.stringify(objState);
  //   // object, previous state, current state
  //   this._history.keep([obj, state, state]);
  // };

  _onObjectAdded = (e) => {
    const canvas = this._fc;
    
    // If no action is set, set it to true and return
    if (!this.state.action) {
      this.setState({ action: true });
      return;
    }
  
    // Handle send-to-back logic if enabled
    if (this.props.sendToBackEnabled) {
      setTimeout(() => {
        const arr = canvas.getObjects();
        canvas.sendToBack(arr[arr.length - 1]);
        canvas.requestRenderAll();
      }, 15);
    }
  
    // Get the added object
    const obj = e.target;
    obj.__version = 1;
  
    // Record the current state of the object as JSON
    const objState = obj.toJSON();
    obj.__originalState = objState;
  
    // Save the state as a string for history tracking
    const state = JSON.stringify(objState);
    
    // Keep the object, its previous state, and current state for history
    this._history.keep([obj, state, state]);
  };

  
  /**
   * Action when an object is moving around inside the canvas
   */
  _onObjectMoving = (e) => {};

  /**
   * Action when an object is scaling inside the canvas
   */
  _onObjectScaling = (e) => {};

  /**
   * Action when an object is rotating inside the canvas
   */
  _onObjectRotating = (e) => {};

  _onObjectModified = (e) => {
    let obj = e.target;
    obj.__version += 1;
    let prevState = JSON.stringify(obj.__originalState);
    let objState = obj.toJSON();
    // record current object state as json and update to originalState
    obj.__originalState = objState;
    let currState = JSON.stringify(objState);
    this._history.keep([obj, prevState, currState]);
  };

  /**
   * Action when an object is removed from the canvas
   */
  _onObjectRemoved = (e) => {
    let obj = e.target;
    if (obj.__removed) {
      obj.__version += 1;
      return;
    }
    obj.__version = 0;
  };

  /**
   * Action when the mouse button is pressed down
   */

  // _onMouseDown = (e) => {
  //   if (this.props.pickCanvasColor) {
  //     let ctx = this._canvas.getContext("2d");
  //     var p = ctx.getImageData(e.pointer.x, e.pointer.y, 1, 1).data;
  //     var rgba = `rgba(${p[0]},${p[1]},${p[2]},${p[3] / 255})`;
  //     this.props.setCanvasColor(rgba);
  //   }
  //   this._selectedTool.doMouseDown(e);
  // };

  _onMouseDown = (e) => {
  if (this.props.pickCanvasColor) {
    const ctx = this._canvas.getContext("2d");
    if (ctx) {  // Ensure the context is available
      const { x, y } = e.pointer;  // Destructure pointer coordinates
      const p = ctx.getImageData(x, y, 1, 1).data;
      const rgba = `rgba(${p[0]},${p[1]},${p[2]},${p[3] / 255})`;
      this.props.setCanvasColor(rgba);
    }
  }

  // Call the selected tool's mouse down function
  if (this._selectedTool) {
    this._selectedTool.doMouseDown(e);
  }
};

  /**
   * Action when the mouse cursor is moving around within the canvas
   */
  // _onMouseMove = e => {
  //   this._selectedTool.doMouseMove(e);
  //   let canvas = this._fc;
  //   this.getSelectedObj();
  // };

  _onMouseMove = (e) => {
    if (e.e.type === "touchmove") {
      // Handle touch events
      const touchX = e.e.touches[0].clientX;
      const touchY = e.e.touches[0].clientY;
      const touchEvent = {
        e: {
          isTrusted: true,
        },
        target: null,
        subTargets: [],
        button: 1,
        isClick: false,
        pointer: {
          x: touchX,
          y: touchY,
        },
        absolutePointer: {
          x: touchX,
          y: touchY,
        },
        transform: null,
      };
      this._selectedTool.doMouseMove(touchEvent);
      let demoValue = "selectedValue";

      this.getSelectedObj(demoValue);
    } else {
      // Handle mouse events
      this._selectedTool.doMouseMove(e);
      let canvas = this._fc._activeObject;

      this.getSelectedObj(canvas);
    }

    // let canvas = this._fc;
    // this.getSelectedObj();
  };

  /**
   * Action when the mouse cursor is moving out from the canvas
   */
  _onMouseOut = (e) => {
    this._selectedTool.doMouseOut(e);
    if (this.props.onChange) {
      let onChange = this.props.onChange;
      setTimeout(() => {
        onChange(e.e);
      }, 10);
    }
  };

  _onMouseOver = (e) => {};

  _onMouseUp = (e) => {
    this._selectedTool.doMouseUp(e);
    // Update the final state to new-generated object
    // Ignore Path object since it would be created after mouseUp
    // Assumed the last object in canvas.getObjects() in the newest object
    if (this.props.tool !== Tool.Pencil) {
      const canvas = this._fc;
      const objects = canvas.getObjects();
      const newObj = objects[objects.length - 1];
      if (newObj && newObj.__version === 1) {
        newObj.__originalState = newObj.toJSON();
      }
    }
    if (this.props.onChange) {
      let onChange = this.props.onChange;
      setTimeout(() => {
        onChange(e.e);
      }, 10);
    }

    if (this.props.tool == "text") {
      this.props.setSelectedTool("select");
    }
  };

  /**
   * Track the resize of the window and update our state
   *
   * @param e the resize event
   * @private
   */
  _resize = (e) => {
    if (e) e.preventDefault();
    let { widthCorrection, heightCorrection } = this.props;
    let canvas = this._fc;
    let { offsetWidth, clientHeight } = this._container;
    let prevWidth = canvas.getWidth();
    let prevHeight = canvas.getHeight();
    let wfactor = ((offsetWidth - widthCorrection) / prevWidth).toFixed(2);
    let hfactor = ((clientHeight - heightCorrection) / prevHeight).toFixed(2);
    canvas.setWidth(offsetWidth - widthCorrection);
    canvas.setHeight(clientHeight - heightCorrection);
    if (canvas.backgroundImage) {
      // Need to scale background images as well
      let bi = canvas.backgroundImage;
      bi.width = bi.width * wfactor;
      bi.height = bi.height * hfactor;
    }
    let objects = canvas.getObjects();
    for (let i in objects) {
      let obj = objects[i];
      let scaleX = obj.scaleX;
      let scaleY = obj.scaleY;
      let left = obj.left;
      let top = obj.top;
      let tempScaleX = scaleX * wfactor;
      let tempScaleY = scaleY * hfactor;
      let tempLeft = left * wfactor;
      let tempTop = top * hfactor;
      obj.scaleX = tempScaleX;
      obj.scaleY = tempScaleY;
      obj.left = tempLeft;
      obj.top = tempTop;
      obj.setCoords();
    }
    this.setState({
      parentWidth: offsetWidth,
    });
    canvas.renderAll();
    canvas.calcOffset();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    // <= 1024  && windowHeight <= 1366
    let orientationModal = null;
    if(window.matchMedia("(orientation: portrait)").matches){
      orientationModal = Swal.fire({
          text: "Animation-ish works best in landscape. Please rotate your device.",
          icon: "info",
          width:500,
          height: 500,
          confirmButtonColor: "#1b75d0",
          customClass: {
            htmlContainer: "custom_swal_pop_DeviceAndOrientation",
            icon: "custom_ic",
            popup: "alignment_top"
          },
        });
  
    } 
    if(window.matchMedia("(orientation: landscape)").matches && orientationModal){
        Swal.close();
        orientationModal = null
    }
    window.addEventListener("orientationchange", (event) => {
      if(event.target.screen.orientation.angle == 0){
        window.location.reload()
      }
    });

  };


  /**
   * Sets the background color for this sketch
   * @param color in rgba or hex format
   */
  _backgroundColor = (color) => {
    if (!color) return;
    let canvas = this._fc;
    canvas.setBackgroundColor(color, () => canvas.renderAll());
  };

  /**
   * Zoom the drawing by the factor specified
   *
   * The zoom factor is a percentage with regards the original, for example if factor is set to 2
   * it will double the size whereas if it is set to 0.5 it will half the size
   *
   * @param factor the zoom factor
   */
  zoomIn = (factor) => {
    let canvas = this._fc;
    canvas.discardActiveObject();
    canvas.forEachObject(
      (o) => (
        (o.centeredScaling = true),
        (o.selectable = o.evented = o.hasControls = o.hasBorders = false)
      )
    );
    let objects = canvas.getObjects();

    var sel = new fabric.ActiveSelection(canvas.getObjects(), {
      canvas: canvas,
      hasControls: false,
      hasBorders: false,
      hasRotatingPoint: false,
      selectable: false,
    });
    canvas.setActiveObject(sel);
    canvas.requestRenderAll();

    for (let i in objects) {
      this.zoomHistory.push({
        scaleX: objects[i].scaleX,
        scaleY: objects[i].scaleY,
        left: objects[i].left,
        top: objects[i].top,
      });

      objects[i].scaleX = objects[i].scaleX * factor;
      objects[i].scaleY = objects[i].scaleY * factor;
      objects[i].left = objects[i].left * factor;
      objects[i].top = objects[i].top * factor;
      objects[i].setCoords();
    }
    canvas.renderAll();
    canvas.calcOffset();

    canvas.discardActiveObject();
  };

  zoomOut = (factor) => {
    let canvas = this._fc;
    canvas.discardActiveObject();
    canvas.forEachObject(
      (o) => (
        (o.centeredScaling = true),
        (o.selectable = o.evented = o.hasControls = o.hasBorders = false)
      )
    );
    let objects = canvas.getObjects();
    var sel = new fabric.ActiveSelection(canvas.getObjects(), {
      canvas: canvas,
      hasControls: false,
      hasBorders: false,
      hasRotatingPoint: false,
      selectable: false,
    });
    canvas.setActiveObject(sel);
    canvas.requestRenderAll();
    for (let i in objects) {
      this.zoomHistory.push({
        scaleX: objects[i].scaleX,
        scaleY: objects[i].scaleY,
        left: objects[i].left,
        top: objects[i].top,
      });
      objects[i].scaleX = objects[i].scaleX / factor;
      objects[i].scaleY = objects[i].scaleY / factor;
      objects[i].left = objects[i].left / factor;
      objects[i].top = objects[i].top / factor;
      objects[i].setCoords();
    }
    canvas.renderAll();
    canvas.calcOffset();

    canvas.discardActiveObject();
  };

  // Reset Zoom
  resetZoom = () => {
    let canvas = this._fc;
    canvas.discardActiveObject();
    canvas.forEachObject(
      (o) => (
        (o.centeredScaling = true),
        (o.selectable = o.evented = o.hasControls = o.hasBorders = false)
      )
    );
    if (this.zoomHistory.length > 0) {
      let objects = canvas.getObjects();
      var sel = new fabric.ActiveSelection(canvas.getObjects(), {
        canvas: canvas,
        hasControls: false,
        hasBorders: false,
        hasRotatingPoint: false,
        selectable: false,
      });
      canvas.selection = false;
      canvas.hasBorders = false;
      canvas.hasControls = false;
      canvas.setActiveObject(sel);
      canvas.requestRenderAll();
      for (let i in objects) {
        objects[i].scaleX = this.zoomHistory[i].scaleX;
        objects[i].scaleY = this.zoomHistory[i].scaleY;
        objects[i].left = this.zoomHistory[i].left;
        objects[i].top = this.zoomHistory[i].top;
        objects[i].setCoords();
      }
      canvas.renderAll();
      canvas.calcOffset();
      this.zoomHistory = [];
    }

    canvas.discardActiveObject();
  };

  setInitialZoomAndPosition = () => {
    let canvas = this._fc;
    let canvasWidth = canvas.getWidth();
    let canvasHeight = canvas.getHeight();

    canvas.getObjects().forEach((obj) => {
      let scaleX = canvasWidth / obj.width;
      let scaleY = canvasHeight / obj.height;

      obj.scaleX = scaleX;
      obj.scaleY = scaleY;

      obj.setCoords();
    });

    canvas.getObjects().forEach((obj) => {
      canvas.centerObject(obj);
    });

    // Render and recalculate offset
    canvas.renderAll();
    canvas.calcOffset();
  };

  // Zoom In
  zoomIn1() {
    let canvas = this._fc;
    if (canvas.getZoom().toFixed(5) > zoomMax) {
      return;
    }

    canvas.setZoom(canvas.getZoom() * SCALE_FACTOR);
    canvas.setHeight(canvas.getHeight() * SCALE_FACTOR);
    canvas.setWidth(canvas.getWidth() * SCALE_FACTOR);
    canvas.renderAll();
  }

  // Zoom Out
  zoomOut1() {
    let canvas = this._fc;
    canvas.setZoom(canvas.getZoom() / SCALE_FACTOR);
    canvas.setHeight(canvas.getHeight() / SCALE_FACTOR);
    canvas.setWidth(canvas.getWidth() / SCALE_FACTOR);
    canvas.renderAll();
  }
  // Reset Zoom
  resetZoom1() {
    let canvas = this._fc;
    canvas.setHeight(canvas.getHeight() / canvas.getZoom());
    canvas.setWidth(canvas.getWidth() / canvas.getZoom());
    canvas.setZoom(1);

    canvas.renderAll();

    this.getFabricCanvases().forEach(function (item) {
      item.css("left", 0);
      item.css("top", 0);
    });
  }

  getFabricCanvases = (function () {
    var fabricCanvasCollection;
    return function getCanvases() {
      if (!fabricCanvasCollection) {
        fabricCanvasCollection = [];
        var fabricCanvas = $(".canvas-container canvas");
        fabricCanvas.each(function (index, item) {
          fabricCanvasCollection.push($(item));
        });
      }
      return fabricCanvasCollection;
    };
  })();

  /**
   * Perform an undo operation on canvas, if it cannot undo it will leave the canvas intact
   */
  undo = () => {
    let history = this._history;
    if (history.getCurrent() === null) return;
    let [obj, prevState, currState] = history.getCurrent();
    history.undo();
    if (obj.__removed) {
      this.setState({ action: false }, () => {
        this._fc.add(obj);
        obj.__version -= 1;
        obj.__removed = false;
      });
    } else if (obj.__version <= 1) {
      this._fc.remove(obj);
      if (obj.__originalState.type === "image") {
        // this.imageCanvas123 = new fabric.Canvas();
        // this.imageCanvas123.setDimensions({
        //   width: 500,
        //   height: 500
        // });
        // this.imageCanvas123.add(history.current[0]);
        // this._fc.add(history.current[0]);
        // this._fc.renderAll();
      }
    } else {
      obj.__version -= 1;
      obj.setOptions(JSON.parse(prevState));
      obj.setCoords();
      this._fc.renderAll();
    }
    if (this.props.onChange) {
      this.props.onChange();
    }
  };

  /**
   * Perform a redo operation on canvas, if it cannot redo it will leave the canvas intact
   */
  redo = () => {
    let history = this._history;
    if (history.canRedo()) {
      let canvas = this._fc;
      //noinspection Eslint
      let [obj, prevState, currState] = history.redo();
      if (obj.__version === 0) {
        this.setState({ action: false }, () => {
          canvas.add(obj);
          obj.__version = 1;
        });
      } else {
        obj.__version += 1;
        obj.setOptions(JSON.parse(currState));
      }
      obj.setCoords();
      canvas.renderAll();
      if (this.props.onChange) {
        this.props.onChange();
      }
    }
  };

  /**
   * Delegation method to check if we can perform an undo Operation, useful to disable/enable possible buttons
   *
   * @returns {*} true if we can undo otherwise false
   */
  canUndo = () => {
    return this._history.canUndo();
  };

  /**
   * Delegation method to check if we can perform a redo Operation, useful to disable/enable possible buttons
   *
   * @returns {*} true if we can redo otherwise false
   */
  canRedo = () => {
    return this._history.canRedo();
  };

  /**
   * Exports canvas element to a dataurl image. Note that when multiplier is used, cropping is scaled appropriately
   *
   * Available Options are
   * <table style="width:100%">
   *
   * <tr><td><b>Name</b></td><td><b>Type</b></td><td><b>Argument</b></td><td><b>Default</b></td><td><b>Description</b></td></tr>
   * <tr><td>format</td> <td>String</td> <td><optional></td><td>png</td><td>The format of the output image. Either "jpeg" or "png"</td></tr>
   * <tr><td>quality</td><td>Number</td><td><optional></td><td>1</td><td>Quality level (0..1). Only used for jpeg.</td></tr>
   * <tr><td>multiplier</td><td>Number</td><td><optional></td><td>1</td><td>Multiplier to scale by</td></tr>
   * <tr><td>left</td><td>Number</td><td><optional></td><td></td><td>Cropping left offset. Introduced in v1.2.14</td></tr>
   * <tr><td>top</td><td>Number</td><td><optional></td><td></td><td>Cropping top offset. Introduced in v1.2.14</td></tr>
   * <tr><td>width</td><td>Number</td><td><optional></td><td></td><td>Cropping width. Introduced in v1.2.14</td></tr>
   * <tr><td>height</td><td>Number</td><td><optional></td><td></td><td>Cropping height. Introduced in v1.2.14</td></tr>
   *
   * </table>
   *
   * @returns {String} URL containing a representation of the object in the format specified by options.format
   */
  toDataURL = (options) => this._fc.toDataURL(options);

  /**
   * Returns JSON representation of canvas
   *
   * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
   * @returns {string} JSON string
   */
  toJSON = (propertiesToInclude) => this._fc.toJSON(propertiesToInclude);

  /**
   * Populates canvas with data from the specified JSON.
   *
   * JSON format must conform to the one of fabric.Canvas#toDatalessJSON
   *
   * @param json JSON string or object
   */
  fromJSON = (json) => {
    if (!json) return;
    let canvas = this._fc;
    setTimeout(() => {
      canvas.loadFromJSON(json, () => {
        canvas.renderAll();
        if (this.props.onChange) {
          this.props.onChange();
        }
      });
    }, 100);
  };

  /**
   * Clear the content of the canvas, this will also clear history but will return the canvas content as JSON to be
   * used as needed in order to undo the clear if possible
   *
   * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
   * @returns {string} JSON string of the canvas just cleared
   */
  clear = (propertiesToInclude) => {
    let discarded = this.toJSON(propertiesToInclude);
    this._fc.clear();
    this._history.clear();
    return discarded;
  };

  /**
   * Remove selected object from the canvas
   */
  removeSelected = () => {
    let canvas = this._fc;
    let activeObj = canvas.getActiveObject();
    if (activeObj) {
      let selected = [];
      if (activeObj.type === "activeSelection") {
        activeObj.forEachObject((obj) => selected.push(obj));
      } else {
        selected.push(activeObj);
      }
      selected.forEach((obj) => {
        obj.__removed = true;
        let objState = obj.toJSON();
        obj.__originalState = objState;
        let state = JSON.stringify(objState);
        this._history.keep([obj, state, state]);
        canvas.remove(obj);
      });
      canvas.discardActiveObject();
      canvas.requestRenderAll();
    }
    this.getSelectedObj();
  };

  cut = () => {
    let canvas = this._fc;
    if (canvas.getActiveObject()) {
      canvas.getActiveObject().clone((cloned) => (this._clipboard = cloned));
      this.removeSelected();
      this.props.checkIfPasteIsEnabled(true);
    }
  };

  copy = () => {
    let canvas = this._fc;
    if (canvas.getActiveObject())
      canvas.getActiveObject().clone((cloned) => (this._clipboard = cloned));
    this.props.checkIfPasteIsEnabled(true);
  };

  paste = () => {
    // clone again, so you can do multiple copies.

    if (this._clipboard)
      this._clipboard.clone((clonedObj) => {
        let canvas = this._fc;
        canvas.discardActiveObject();
        clonedObj.set({
          left: clonedObj.left + 10,
          top: clonedObj.top + 10,
          evented: true,
        });
        if (clonedObj.type === "activeSelection") {
          // active selection needs a reference to the canvas.
          clonedObj.canvas = canvas;
          clonedObj.forEachObject((obj) => canvas.add(obj));
          clonedObj.setCoords();
        } else {
          canvas.add(clonedObj);
        }
        this._clipboard.top += 10;
        this._clipboard.left += 10;
        canvas.setActiveObject(clonedObj);
        canvas.requestRenderAll();
      });
  };

  /**
   * Sets the background from the dataUrl given
   *
   * @param dataUrl the dataUrl to be used as a background
   * @param options
   */
  setBackgroundFromDataUrl = (dataUrl, options = {}) => {
    let canvas = this._fc;
    if (options.stretched) {
      delete options.stretched;
      Object.assign(options, {
        width: canvas.width,
        height: canvas.height,
      });
    }
    if (options.stretchedX) {
      delete options.stretchedX;
      Object.assign(options, {
        width: canvas.width,
      });
    }
    if (options.stretchedY) {
      delete options.stretchedY;
      Object.assign(options, {
        height: canvas.height,
      });
    }
    let img = new Image();
    img.onload = () =>
      canvas.setBackgroundImage(
        new fabric.Image(img),
        () => canvas.renderAll(),
        options
      );
    img.src = dataUrl;
  };

  addText = (text, options = {}) => {
    let canvas = this._fc;
    let iText = new fabric.IText(text, options);
    let opts = {
      left: (canvas.getWidth() - iText.width) * 0.5,
      top: (canvas.getHeight() - iText.height) * 0.5,
    };
    Object.assign(options, opts);
    iText.set({
      left: options.left,
      top: options.top,
    });

    canvas.add(iText);
  };

  _onObjectSelected = (e) => {
    // let canvas = this._fc;
    // let selected = this._fc.getActiveObject();
    // // selected.set("backgroundColor", "green");
    // canvas.renderAll();
    this.getSelectedObj();
  };

  getSelectedObj = (canvas) => {
    // let selected = this._fc._activeObject;
    if (this.props.checkIfObjectIsSelected) {
      this.props.checkIfObjectIsSelected(canvas);
    }
  };

  toggleBackground = (status, keyframe) => {
    let canvas = this._fc,
      base64;

    this.imageCanvas.loadFromJSON(keyframe, () => {
      this.imageCanvas.forEachObject(function (object) {
        object.opacity = 1;
      });

      base64 = this.imageCanvas.toDataURL();
      this.setState({
        canvasBackground: base64,
      });
    });
  };

 
  

  updateAnimation() {
    const canvas = this._fc;
    // Using transformPath from either this._fc or from the active keyframe
    const transformPath = this._fc?.transformPath || this.props.keyframes[this.props.activeKeyframeIndex]?.transformPath;
  
    if (!transformPath || this.animatedIndex >= transformPath.length) {
      // Exit if no transformPath or the index exceeds the array length
      return;
    }
  
    const activeItem = canvas.getActiveObject();
    if (activeItem) {
      // Update the position of the active object based on the transformPath values
      activeItem.set({
        top: transformPath[this.animatedIndex].y,
        left: transformPath[this.animatedIndex].x,
      });
  
      // Increment the animated index
      this.animatedIndex++;
  
      // Render all shapes on the canvas
      canvas.requestRenderAll();
  
      // Queue the next update if we haven't reached the end of the transformPath
      if (this.animatedIndex < transformPath.length) {
        // Use an arrow function to bind `this` context correctly
        requestAnimationFrame(() => this.updateAnimation());
      } else {
        // Reset the animatedIndex and set properties for the active item
        this.animatedIndex = 1;
        if (activeItem) {
          activeItem.set({
            cornerSize: 12,
            hasBorders: true,
            transparentCorners: false,
          });
        }
      }
    }
  }
  

  animatePath = () => {
    let activeItem = this._fc.getActiveObject();
    const transformPath =
      this._fc?.transformPath ||
      this.props.keyframes[this.props.activeKeyframeIndex].transformPath;

    if (activeItem && transformPath) {
      this.props.onMovePathChange(
        transformPath,
        this.props.activeKeyframeIndex
      );
      activeItem.set({
        cornerSize: 0,
        hasBorders: false,
        transparentCorners: false,
      });
      requestAnimationFrame(this.updateAnimation);
      // this._fc.startAnimationTransform();
    }
    // const transformPath = this._fc;
  };

  deselectActiveObjects = () => {
    let canvas = this._fc;
    canvas.discardActiveObject();
    canvas.requestRenderAll();
  };

  deleteBackgroundForegroundObjs = (status) => {
    let canvas = this._fc;
    let arr = canvas.getObjects();

    if (arr.length > 0) {
      if (status == "foreground") {
        let foregroundObjects = _.filter(arr, (e) => {
          return e.globalCompositeOperation == "foreground";
        });
        for (let i in foregroundObjects) {
          canvas.remove(foregroundObjects[i]);
        }
        canvas.renderAll();
        this.props.onChange();
      } else {
        let backgroundObjects = _.filter(arr, (e) => {
          return e.globalCompositeOperation == "background";
        });
        for (let i in backgroundObjects) {
          canvas.remove(backgroundObjects[i]);
        }
        canvas.renderAll();
        this.props.onChange();
      }
    }
  };

 

  componentDidMount = () => {
    let {
        tool,
        value,
        undoSteps,
        defaultValue,
        backgroundColor,
        width,
        height,
      } = this.props,
      context = this;

    let canvas = (this._fc = new fabric.Canvas(this._canvas, {
      preserveObjectStacking: true,
      enableRetinaScaling: false,
      centeredScaling: true,
      // renderOnAddRemove: false,
      // skipTargetFind: true
    }));

    // setTimeout(() => {
    //   canvas.setOverlayImage(
    //     "https://ak1.picdn.net/shutterstock/videos/16356931/thumb/1.jpg",
    //     canvas.renderAll.bind(canvas),
    //     {
    //       opacity: 0.5,
    //       left: 10,
    //       top: 10,

    //       originX: "left",
    //       originY: "top"
    //     }
    //   );
    // }, 2000);

    this.imageCanvas = new fabric.Canvas();
    this.imageCanvas.setDimensions({
      width: width,
      height: height,
    });

    this._initTools(canvas);

    // set initial backgroundColor
    this._backgroundColor(backgroundColor);

    let selectedTool = this._tools[tool];
    selectedTool.configureCanvas(this.props);
    this._selectedTool = selectedTool;

    // Control resize
    window.addEventListener("resize", this._resize, false);

    // Initialize History, with maximum number of undo steps
    this._history = new History(undoSteps);

    // Events binding
    canvas.on("object:added", this._onObjectAdded);
    canvas.on("object:modified", this._onObjectModified);
    canvas.on("object:removed", this._onObjectRemoved);
    canvas.on("object:selected", this._onObjectSelected);
    canvas.on("mouse:down", this._onMouseDown);
    canvas.on("mouse:move", this._onMouseMove);
    canvas.on("touch:move", this._onMouseMove);
    canvas.on("mouse:up", this._onMouseUp);
    canvas.on("mouse:over", this._onMouseOver);
    canvas.on("mouse:out", this._onMouseOut);
    canvas.on("object:moving", this._onObjectMoving);
    canvas.on("object:scaling", this._onObjectScaling);
    canvas.on("object:rotating", this._onObjectRotating);

    // IText Events fired on Adding Text
    this.disableTouchScroll();
    this._resize();

    

    // initialize canvas with controlled value if exists
    (value || defaultValue) && this.fromJSON(value || defaultValue);

    // perform action on arrow key pressed
    document.onkeyup = function (e) {
      switch (e.keyCode) {
        case 38 /* Up arrow */:
          if (canvas.getActiveObject()) {
            canvas.getActiveObject().top -= 5;
            canvas.renderAll();
            context.props.onChange();
          }
          break;
        case 40 /* Down arrow  */:
          if (canvas.getActiveObject()) {
            canvas.getActiveObject().top += 5;
            canvas.renderAll();
            context.props.onChange();
          }
          break;
        case 37 /* Left arrow  */:
          if (canvas.getActiveObject()) {
            canvas.getActiveObject().left -= 5;
            canvas.renderAll();
            context.props.onChange();
          }
          break;
        case 39 /* Right arrow  */:
          if (canvas.getActiveObject()) {
            canvas.getActiveObject().left += 5;
            canvas.renderAll();
            context.props.onChange();
          }
          break;
      }
    };
  };


  componentDidUpdate = async (prevProps, prevState) => {
    if (
      this.state.parentWidth !== prevState.parentWidth ||
      this.props.width !== prevProps.width ||
      this.props.height !== prevProps.height 
    ) {
      this._resize();
    }
    
    if (this.props.tool !== prevProps.tool) {
      this._selectedTool =
        this._tools[this.props.tool] || this._tools[Tool.Line];
    }

    if (this.props.tool != "paintBucket") {
      let fcanvas = this._fc;
      fcanvas.off("mouse:down");
      fcanvas.on("mouse:down", this._onMouseDown);
    }

    if (this.props.tool == "text") {
      let fcanvas = this._fc;
      fcanvas.on("mouse:up", this._onMouseUp);
    }

    //Bring the cursor back to default if it is changed by a tool
    this._fc.defaultCursor = this.props.tool === "select" ? "default" :"crosshair";
    this._selectedTool.configureCanvas(this.props);

    if(this.props.tool == "circle"){
      let fcanvas = this._fc;
    }

    if (this.props.backgroundColor !== prevProps.backgroundColor) {
      this._backgroundColor(this.props.backgroundColor);
    }

    if (
      this.props.value !== prevProps.value ||
      (this.props.value && this.props.forceValue)
    ) {
      this.fromJSON(this.props.value);
    }

    if (prevProps != this.props) {
      let context = this;
      if (this.props.traceEnabled && this.props.activeKeyframeIndex != 0) {
        var canvas = this._fc;

        let indexFg = _.findIndex(
          this.props.keyframes,
          this.props.keyframes[this.props.activeKeyframeIndex - 1]
        );

        let indexBg = _.findIndex(
          this.props.bgKeyframes,
          this.props.bgKeyframes[this.props.activeKeyframeIndex - 1]
        );
        if (
          this.props.keyframes[indexBg] &&
          this.props.selectedSpace == "background"
        ) {
          this.setState(
            {
              traceBackgroundImgBg: "",
            },
            () => {
              this.imageCanvas.loadFromJSON(
                this.props.bgKeyframes[indexBg].keyframe,
                // JSON.stringify(this.props.bgKeyframes[indexBg].keyframe),

                () => {
                  this.imageCanvas.forEachObject(function (object) {
                    object.opacity = 0.1;
                  });

                  this.setState({
                    traceBackgroundImgBg: this.imageCanvas.toDataURL(),
                  });
                }
              );
            }
          );
        } else if (
          (this.props.keyframes[indexFg] &&
            this.props.selectedSpace == "foreground") ||
          this.props.keyframes[indexFg]
        ) {
          this.setState(
            {
              traceBackgroundImgFg: "",
            },
            () => {
              this.imageCanvas.loadFromJSON(
                this.props.keyframes[indexFg].keyframe,
                () => {
                  this.imageCanvas.forEachObject(function (object) {
                    object.opacity = 0.1;
                  });

                  this.setState({
                    traceBackgroundImgFg: this.imageCanvas.toDataURL(),
                  });
                }
              );
            }
          );
        }
      } else if (
        !this.props.traceEnabled ||
        this.props.activeKeyframeIndex === 0
      ) {
        this.setState({
          traceBackgroundImgFg: "",
          traceBackgroundImgBg: "",
        });
      }
      // if condition to change the stroke color of a selected object when color is changed from color pallete
      if (
        prevProps.randomColorState != this.props.randomColorState &&
        this._fc.getActiveObject()
      ) {
        if (!this._fc.getActiveObject()._objects) {
          this._fc.getActiveObject().set({ stroke: this.props.lineColor });
          this._fc.renderAll();
        } else {
          this._fc.getActiveObject()._objects.length > 0 &&
            this._fc.getActiveObject()._objects.map((item, index) => {
              item.set({ stroke: this.props.lineColor });
              this._fc.renderAll();
            });
        }

        if (
          this._fc.getActiveObject() &&
          this._fc.getActiveObject().__originalState &&
          this._fc.getActiveObject().__originalState.src
        ) {
          const result = await analyze(
            this._fc.getActiveObject().__originalState.src
          ); // also supports base64 encoded image strings

          if (
            result.length == 1 &&
            this._fc.getActiveObject().__originalState &&
            this._fc.getActiveObject().__originalState.src
          ) {
            let newColor = this.changeColInUri(
              this._fc.getActiveObject().__originalState.src,
              "",
              this.props.lineColor
            );
            let index = _.findIndex(this._fc._objects, (e) => {
              return e.cacheKey == this._fc.getActiveObject().cacheKey;
            });
            let canvas = this._fc,
              context = this;
            canvas.getActiveObject().setSrc(newColor, function () {
              canvas.getActiveObject().set("dirty", true);
              canvas.renderAll();
              context.props.onChange();
            });
          } else {
            alert("can not fill");
          }
        }
      }
    }
  };

  componentWillUnmount = () =>
    window.removeEventListener("resize", this._resize);

  replaceImgSrc() {}

  changeColInUri(data, colfrom, colto) {
    // create fake image to calculate height / width
    var img = document.createElement("img");
    img.src = data;
    img.style.visibility = "hidden";
    document.body.appendChild(img);

    var canvas = document.createElement("canvas");
    canvas.width = img.offsetWidth;
    canvas.height = img.offsetHeight;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    // remove image
    img.parentNode.removeChild(img);

    // do actual color replacement
    var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    var data = imageData.data;

    var rgbfrom = this.hexToRGB(colfrom);
    var rgbto = this.hexToRGB(colto);

    var r, g, b;
    for (var x = 0, len = data.length; x < len; x += 4) {
      r = data[x];
      g = data[x + 1];
      b = data[x + 2];

      data[x] = rgbto.r;
      data[x + 1] = rgbto.g;
      data[x + 2] = rgbto.b;
    }

    ctx.putImageData(imageData, 0, 0);
    return canvas.toDataURL();
    canvas.renderAll()
  }

  hexToRGB(hexStr) {
    var col = {};
    col.r = parseInt(hexStr.substr(1, 2), 16);
    col.g = parseInt(hexStr.substr(3, 2), 16);
    col.b = parseInt(hexStr.substr(5, 2), 16);

    return col;
  }

  canvasCursor(tool) {
    if (
      tool === "paintBucket" &&
      document.getElementsByClassName("canvas-container")[0]
    ) {
      document
        .getElementsByClassName("canvas-container")[0]
        .childNodes[0].childNodes[1].classList.remove("eraserCursor");

      document
        .getElementsByClassName("canvas-container")[0]
        .childNodes[0].childNodes[1].classList.remove("eyedropperCursor");

      document
        .getElementsByClassName("canvas-container")[0]
        .childNodes[0].childNodes[1].classList.add("paintCursor");

      return "paintCursor";
    } else if (
      tool === "eraser" &&
      document.getElementsByClassName("canvas-container")[0]
    ) {
      document
        .getElementsByClassName("canvas-container")[0]
        .childNodes[0].childNodes[1].classList.remove("paintCursor");

      document
        .getElementsByClassName("canvas-container")[0]
        .childNodes[0].childNodes[1].classList.remove("eyedropperCursor");

      document
        .getElementsByClassName("canvas-container")[0]
        .childNodes[0].childNodes[1].classList.add("eraserCursor");

      return "eraserCursor";
    } else if (
      tool === "eyedropper" &&
      document.getElementsByClassName("canvas-container")[0]
    ) {
      document
        .getElementsByClassName("canvas-container")[0]
        .childNodes[0].childNodes[1].classList.remove("eraserCursor");

      document
        .getElementsByClassName("canvas-container")[0]
        .childNodes[0].childNodes[1].classList.add("eyedropperCursor");

      return "eyedropperCursor";
    }
    // else if (
    //   tool === "transform" &&
    //   document.getElementsByClassName("canvas-container")[0]
    // ) {

    //   document
    //     .getElementsByClassName("canvas-container")[0]
    //     .childNodes[0].childNodes[1].classList.remove("eraserCursor");

    //   document
    //     .getElementsByClassName("canvas-container")[0]
    //     .childNodes[0].childNodes[1].classList.add("eyedropperCursor");

    //   return "pencil";
    // }
    else {
      if (document.getElementsByClassName("canvas-container")[0]) {
        document
          .getElementsByClassName("canvas-container")[0]
          .childNodes[0].childNodes[1].classList.remove("paintCursor");

        document
          .getElementsByClassName("canvas-container")[0]
          .childNodes[0].childNodes[1].classList.remove("eraserCursor");

        document
          .getElementsByClassName("canvas-container")[0]
          .childNodes[0].childNodes[1].classList.remove("eyedropperCursor");
      }
    }
  }

  onPanStop(e) {
    let transformVal =
      document.getElementsByClassName("canvas-container")[0].style.transform;
    this.props.onPanStop(transformVal);
  }

  render = () => {
    let {
      className,
      style,
      width,
      height,
      tool,
      panTransformVal,
      selectedSpace,
    } = this.props;

    let canvasDivStyle = Object.assign(
      {},
      style ? style : {},
      width ? { width: width } : {},
      height ? { height: height } : { height: 512 },
      selectedSpace == "foreground"
        ? { backgroundImage: `url(${this.state.traceBackgroundImgFg})` }
        : {},
      selectedSpace == "background"
        ? { backgroundImage: `url(${this.state.traceBackgroundImgBg})` }
        : {},
      selectedSpace == null
        ? { backgroundImage: `url(${this.state.traceBackgroundImgFg})` }
        : {}
    );

    let panX_val = 0;
    let panY_val = 0;

    // if(this.props.advancedSection && this.props.import_img){
    //   panX_val = -220;
    //   panY_val = -150;
    // }

    let pantransformValArr =
        panTransformVal &&
        panTransformVal.length > 0 &&
        panTransformVal.replace(/.*\(|\)/gi, "").split(","),
      panX =
        pantransformValArr && pantransformValArr[0]
          ? Number(pantransformValArr[0].replace("px", ""))
          : // : -240,
            panX_val,
      panY =
        pantransformValArr && pantransformValArr[1]
          ? Number(pantransformValArr[1].replace("px", ""))
          : // : -130;
            panY_val;
    return (
      <Draggable
        disabled={!this.props.draggableIdActive}
        onStop={(e) => this.onPanStop(e)}
        // defaultPosition={this.props.advancedSection ? { x: -240, y: -130 } : {}}
        defaultPosition={this.props.advancedSection ? { x: 0, y: 0 } : {}}
        position={this.props.advancedSection ? { x: panX, y: panY } : {}}
      >
        <div
          className={classnames({
            "canvas-container": true,
            "canvas-container-advanced": this.props.advancedSection,
          })}
          ref={(c) => (this._container = c)}
          style={canvasDivStyle}
        >
          <canvas
            // id={uuid4()}
            id={'Static-canvas'}
            ref={(c) => (this._canvas = c)}
            className={`${this.canvasCursor(tool)} canvas-size`}
            style={{
              background: `url(
                ${this.state.canvasBackground}
              )`,
            }}
          >
            Sorry, Canvas HTML5 element is not supported by your browser :(
          </canvas>
        </div>
      </Draggable>
    );
  };
}

export default SketchField;
