

import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import classnames from "classnames";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import _ from "underscore";

const fabric = require("fabric").fabric;

class BottomTools extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selected: `${
				props.lastIndexWhereObjectExists == -1
					? 1
					: props.lastIndexWhereObjectExists + 1
			}foreground`
		};
		this.firstObjectExistsAt = null;
		// this._imageCanvas = new fabric.Canvas();
	}

	checkIfItIsAFirstPinBox(index) {
		let { bgKeyframes, keyframes } = this.props;

		if (
			this.firstObjectExistsAt == null &&
			bgKeyframes[index] &&
			bgKeyframes[index].keyframe &&
			bgKeyframes[index].keyframe.objects &&
			bgKeyframes[index].keyframe.objects.length > 0
		) {
			this.firstObjectExistsAt = index;
			return true;
		} else {
			return false;
		}
	}

 
	selectedFrameHasForegroundObjects(keyframes, index) {
		let hasForegroundObject = false;

		keyframes.some(e => {
			if (
				e.keyframe &&
				e.keyframe.objects &&
				e.keyframe.objects.length > 0
			) {
				e.keyframe.objects.some(f => {
					if (f.globalCompositeOperation == "foreground")
						hasForegroundObject = true;
				});
			}
		});

		return (
			keyframes[index] &&
			keyframes[index].keyframe &&
			keyframes[index].keyframe.objects &&
			keyframes[index].keyframe.objects.length > 0
		);
	}

	selectedFrameHasBackgroundObjects(keyframes, index) {
		let hasBackGroundObject = false;
		let { bgKeyframes } = this.props;
		keyframes.some(e => {
			if (
				e.background &&
				e.background.keyframe &&
				e.background.keyframe.objects &&
				e.background.keyframe.objects.length > 0
			) {
				e.background.keyframe.objects.some(f => {
					if (f.globalCompositeOperation == "background")
						hasBackGroundObject = true;
				});
			}
		});

		return (
			bgKeyframes[index] &&
			bgKeyframes[index].keyframe &&
			bgKeyframes[index].keyframe.objects &&
			bgKeyframes[index].keyframe.objects.length > 0
		);
	}

	handleCellClicked(index, whichGround) {
		// if (whichGround == "background") return;

		let { playing, looping } = this.props;
		if (playing || looping) {
			return;
		}
		this.setState({
			selected: `${index}${whichGround}`
			
		});

		this.props.cellClicked(index, whichGround);
	}

	checkEnableDisableExposure() {
		let {
			selectedSpace,
			keyframes,
			bgKeyframes,
			activeKeyframeIndex
		} = this.props;
		
		if (selectedSpace == "foreground") {
			return (
				keyframes[activeKeyframeIndex] &&
				keyframes[activeKeyframeIndex].keyframe &&
				keyframes[activeKeyframeIndex].keyframe.objects &&
				keyframes[activeKeyframeIndex].keyframe.objects.length > 0
			);
		} else {
			return (
				bgKeyframes[activeKeyframeIndex] &&
				bgKeyframes[activeKeyframeIndex].keyframe &&
				bgKeyframes[activeKeyframeIndex].keyframe.objects &&
				bgKeyframes[activeKeyframeIndex].keyframe.objects.length > 0
			);
		}
	}

	checkEnableDisableDuplicate() {
		let {
			selectedSpace,
			keyframes,
			bgKeyframes,
			activeKeyframeIndex
		} = this.props;

		if (selectedSpace == "foreground") {
			return (
				keyframes[activeKeyframeIndex] &&
				keyframes[activeKeyframeIndex].keyframe &&
				keyframes[activeKeyframeIndex].keyframe.objects &&
				keyframes[activeKeyframeIndex].keyframe.objects.length > 0
			);
		} else {
			return (
				bgKeyframes[activeKeyframeIndex] &&
				bgKeyframes[activeKeyframeIndex].keyframe &&
				bgKeyframes[activeKeyframeIndex].keyframe.objects &&
				bgKeyframes[activeKeyframeIndex].keyframe.objects.length > 0
			);
		}
	}

	renderBorderBox() {
		let { bgKeyframes, keyframes } = this.props,
			lastFg,
			lastBg,
			firstFg,
			firstBg,
			lastAt,
			firstAt,
			onlyOneCellAt;

		lastFg = _.findLastIndex(keyframes, e => {
			return (
				e.keyframe &&
				e.keyframe.objects &&
				e.keyframe.objects.length > 0
			);
		});

		lastBg = _.findLastIndex(bgKeyframes, e => {
			return (
				e.keyframe &&
				e.keyframe.objects &&
				e.keyframe.objects.length > 0
			);
		});
		
		if (lastBg == -1) {
			if (lastFg > lastBg) {
				lastAt = lastFg;
			} else if (lastFg < lastBg) {
				lastAt = lastBg;
			} else {
				lastAt = "noLast";
			}
		} else {
			if (lastBg > lastFg) {
				lastAt = lastBg;
			} else if (lastBg < lastFg) {
				lastAt = lastFg;
			} else if (lastBg == lastFg) {
				lastAt = lastBg;
			} else {
				lastAt = "noLast";
			}
		}

		firstFg = _.findIndex(keyframes, e => {
			return (
				e.keyframe &&
				e.keyframe.objects &&
				e.keyframe.objects.length > 0
			);
		});

		firstBg = _.findIndex(bgKeyframes, e => {
			return (
				e.keyframe &&
				e.keyframe.objects &&
				e.keyframe.objects.length > 0
			);
		});

		if (firstBg == -1 && (firstFg || firstFg == 0)) {
			if (firstFg > firstBg) {
				firstAt = firstFg;
			} else if (firstFg < firstBg) {
				firstAt = firstBg;
			} else {
				firstAt = "noFirst";
			}
		} else if (firstFg == -1 && (firstBg || firstBg == 0)) {
			if (firstFg > firstBg) {
				firstAt = firstFg;
			} else if (firstFg < firstBg) {
				firstAt = firstBg;
			} else {
				firstAt = "noFirst";
			}
		} else if ((firstBg || firstBg == 0) && (firstFg || firstFg == 0)) {
			if (firstBg > firstFg) {
				firstAt = firstFg;
			} else if (firstBg < firstFg) {
				firstAt = firstBg;
			} else if (firstBg == firstFg) {
				firstAt = firstBg;
			} else {
				firstAt = "noFirst";
			}
		}

		if (firstAt == lastAt) {
			onlyOneCellAt = firstAt;
		}

		return keyframes.map((item, index) => {
			if (onlyOneCellAt == index) {
				return <div key={index} className="singlecellborderbox"></div>;
			} else if (index == firstAt) {
				return <div key={index} className="top_left_border"></div>;
			} else if (index > firstAt && index < lastAt) {
				return <div key={index} className="top_bottom_border"></div>;
			} else if (index === lastAt) {
				return <div key={index} className="top_right_border"></div>;
			} else {
				return <div key={index} className="blankBorderBox" />;
			}
		});
	}

	render() {
		let { selected } = this.state;     
        let arr = Array.from(Array(50).keys());
		const {bgKeyframes}= this.props;
        const SortableItem = SortableElement(({value}) => {
            const {index, items: {keyframes, activeKeyframeIndex} } = value;
			// this.handleCellClicked(activeKeyframeIndex + 1, "foreground")
            return (
                <div
                    className={classnames({
                        first_box: true,
                        active_first_box: index == activeKeyframeIndex
                    })}
                > 
                    <ul>
                        <li
                            className={classnames({
                                pinfirstBox: this.checkIfItIsAFirstPinBox(index)
                            })}
                        >
                            {(index + 1) % 10 == 0 ? index + 1 : ""}
                        </li>
                        <li
                            className={classnames({
                                isMasterExposure: keyframes[index]
                                    ? index ==
                                        keyframes[index].addedExposureOfIndex
                                    : false,
                                clicked_div:
                                    index == activeKeyframeIndex &&
                                    this.state.selected ===
                                        `${index + 1}foreground`,
                                tenth_div: (index + 1) % 10 == 0,
                                select_video_layer:
                                    this.selectedFrameHasForegroundObjects(
                                        keyframes,
                                        index
                                    ) &&
                                    this.state.selected.includes("foreground"),
                                exposuredCell:
                                    keyframes[index] &&
                                    keyframes[index].addedExposure &&
                                    index !=
                                        keyframes[index].addedExposureOfIndex
                                        ? true
                                        : false,
                                lastExposuredCell:
                                    keyframes[index] &&
                                    !keyframes[index].addedExposure &&
                                    keyframes[index - 1] &&
                                    keyframes[index - 1].addedExposure
                                        ? true
                                        : false,
                                selected_video_layer:
                                    this.selectedFrameHasForegroundObjects(
                                        keyframes,
                                        index
                                    ) &&
                                    this.state.selected.includes("background")
                            })}
                            onClick={(event) =>
                                this.handleCellClicked(index + 1, "foreground")
                            }
                        >
                            <div className="video_layer_div" />
                        </li>
                        <li
                            className={classnames({
                                isMasterExposure: bgKeyframes[index]
                                    ? index ==
                                        bgKeyframes[index].addedExposureOfIndex
                                    : false,
                                clicked_div:
                                    index == activeKeyframeIndex &&
                                    this.state.selected ===
                                        `${index + 1}background`,
                                tenth_div: (index + 1) % 10 == 0,
                                exposuredCell:
                                    bgKeyframes[index] &&
                                    bgKeyframes[index].addedExposure &&
                                    index !=
                                        bgKeyframes[index].addedExposureOfIndex
                                        ? true
                                        : false,
                                lastExposuredCell:
                                    bgKeyframes[index] &&
                                    !bgKeyframes[index].addedExposure &&
                                    bgKeyframes[index - 1] &&
                                    bgKeyframes[index - 1].addedExposure
                                        ? true
                                        : false,
                                select_video_layer:
                                    this.selectedFrameHasBackgroundObjects(
                                        [],
                                        index
                                    ) &&
                                    this.state.selected.includes("background"),
                                selected_video_layer:
                                    this.selectedFrameHasBackgroundObjects(
                                        [],
                                        index
                                    ) &&
                                    this.state.selected.includes("foreground")
									
                            })}
                            onClick={() =>
                                this.handleCellClicked(index + 1, "background")
                            }
                        >
                            <div className="video_layer_div" />
                        </li>
                        <div className="cursor_pointer">
                            <img src="https://d3rc7k2n78msdl.cloudfront.net/advanced/cursor.png" />
                        </div>
                    </ul>
                </div>
            );
        });

        const SortableList = SortableContainer(({items}) => 
		<div className="right_layer_part">
		<div className="border_box">
			{this.renderBorderBox()}
		</div>
				{arr.map((item, index) => <SortableItem key={`item-${index}`} index={index} value={{items, index}} />)}
			</div> 
            
        );
		const {activeKeyframeIndex, keyframes} = this.props;
		return (
			<div className="bottom-scale">
				<div className="flip_book_bottom_border advanced_bottom_border">
					<img src="https://d3rc7k2n78msdl.cloudfront.net/advanced/border_bottom.png" />
				</div>
				<div className="flip_book_bottom_data advanced_bottom_data">
					<div className="top_icons">
						<ul>
							<li
								title="Duplicate Frame"
								className={classnames({
									active_advanced: this.checkEnableDisableDuplicate()
								})}
								onClick={() =>
									!this.props.isLoading && this.checkEnableDisableDuplicate()
										? this.props.duplicateKeyframe(
												activeKeyframeIndex + 1
										  )
										: null
								}
							>
								<img src="https://d3rc7k2n78msdl.cloudfront.net/flipbook/nex-prev.png" />
							</li>
							<li
								className="active_advanced"
								title="Add Exposure"
							>
								<img
									src="https://d3rc7k2n78msdl.cloudfront.net/advanced/ne_pre.png"
									onClick={() =>
										this.checkEnableDisableExposure()
											? this.props.addExposure(
													activeKeyframeIndex + 1
											  )
											: null
									}
									className={classnames({
										active_plus: this.checkEnableDisableExposure()
									})}
								/>
							</li>
							<li
								title="Remove Frame"
								className="active_advanced"
								onClick={() =>
									this.props.deleteForegroundBackgroundObjects(
										selected,
										activeKeyframeIndex
									)
								}
							>
								<img src="https://d3rc7k2n78msdl.cloudfront.net/flipbook/minus.png" />
							</li>
							<li
								className="active_advanced"
								title="Add Keyframe"
								// onClick={() =>
								// 	this.props.addNewBlankKeyframe(
								// 		activeKeyframeIndex
								// 	)
								// }
							>
								{/* <img src="https://d3rc7k2n78msdl.cloudfront.net/advanced/plus_box.png" /> */}
							</li>
							<li
								className="active_advanced"
								// onClick={() =>
								// 	this.props.deleteForegroundBackgroundObjects(
								// 		selected,
								// 		activeKeyframeIndex
								// 	)
								// }
							>
								{/* <img
									src="images/advanced/minus_box.png"
									title="Remove Keyframe"
								/> */}
							</li> 
						</ul>
					</div>
				</div>
				<div className="background_foreground_sec">
					<img src="https://d3rc7k2n78msdl.cloudfront.net/advanced/border_sec.png" />
					<div className="foreground_video_sec">
						<div className="video_layer">
							<div className="left_text_part">
								<ul>
									<li>Frames</li>
									<li
										className={classnames({
											select_video_layer: selected.includes(
												"foreground"
											)
										})}
									>
										Foreground
									</li>
									<li
										className={classnames({
											select_video_layer: selected.includes(
												"background"
											)
										})}
									>
										Background
									</li>
								</ul>
							</div>
							<SortableList items={{bgKeyframes, keyframes, activeKeyframeIndex}} onSortEnd={this.props.onSortEnd} axis="x" pressDelay={200} />
                                    
						
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default BottomTools;


