import FabricCanvasTool from "./fabrictool";
import analyze from "rgbaster";

const fabric = require("fabric").fabric;

class PaintBucket extends FabricCanvasTool {
	configureCanvas(props) {
		let fcanvas = this._canvas;
		
		fcanvas.off("mouse:down");
		fcanvas.discardActiveObject();
		fcanvas.requestRenderAll();
		fcanvas.isDrawingMode = false;
		fcanvas.selection = false;
		fcanvas.forEachObject(function (object) {
			object.selectable = false;
		});

		// let hex = props.lineColor.replace("#", "");
		// let arrBuff = new ArrayBuffer(4);
		// let vw = new DataView(arrBuff);
		// vw.setUint32(0, parseInt(hex, 16), false);
		// let arrByte = new Uint8Array(arrBuff);
		// let color = `rgba(${arrByte[1]},${arrByte[2]},${arrByte[3]},${props.opacity})`;


		let color = props.lineColor.includes('rgba') ? hexify(props.lineColor) : props.lineColor;
		let fillColor = color,
			fillTolerance = 50;
		floodFill(true);
		

		// let fillColor = hexify(color),
		// 	fillTolerance = 50;
		// floodFill(true);


		let FloodFill = {
			// Compare subsection of array1's values to array2's values, with an optional tolerance
			withinTolerance: function (array1, offset, array2, tolerance) {
				let length = array2.length,
					start = offset + length;
				tolerance = tolerance || 0;

				// Iterate (in reverse) the items being compared in each array, checking their values are
				// within tolerance of each other
				while (start-- && length--) {
					if (Math.abs(array1[start] - array2[length]) > tolerance) {
						return false;
					}
				}

				return true;
			},

			// The actual flood fill implementation
			fill: function (
				imageData,
				getPointOffsetFn,
				point,
				color,
				target,
				tolerance,
				width,
				height
			) {
				let directions = [
					[1, 0],
					[0, 1],
					[0, -1],
					[-1, 0]
				],
					coords = [],
					points = [point],
					seen = {},
					key,
					x,
					y,
					offset,
					i,
					x2,
					y2,
					minX = -1,
					maxX = -1,
					minY = -1,
					maxY = -1;
				// Keep going while we have points to walk
				while (!!(point = points.pop())) {
					x = point.x;
					y = point.y;
					offset = getPointOffsetFn(x, y);

					// Move to next point if this pixel isn't within tolerance of the color being filled
					if (
						!FloodFill.withinTolerance(
							imageData,
							offset,
							target,
							tolerance
						)
					) {
						continue;
					}

					if (x > maxX) {
						maxX = x;
					}
					if (y > maxY) {
						maxY = y;
					}
					if (x < minX || minX == -1) {
						minX = x;
					}
					if (y < minY || minY == -1) {
						minY = y;
					}
					// Update the pixel to the fill color and add neighbours onto stack to traverse
					// the fill area
					i = directions.length;
					while (i--) {
						// Use the same loop for setting RGBA as for checking the neighbouring pixels
						if (i < 4) {
							imageData[offset + i] = color[i];
							coords[offset + i] = color[i];
						}

						// Get the new coordinate by adjusting x and y based on current step
						x2 = x + directions[i][0];
						y2 = y + directions[i][1];
						key = x2 + "," + y2;

						// If new coordinate is out of bounds, or we've already added it, then skip to
						// trying the next neighbour without adding this one
						if (
							x2 < 0 ||
							y2 < 0 ||
							x2 >= width ||
							y2 >= height ||
							seen[key]
						) {
							continue;
						}

						// Push neighbour onto points array to be processed, and tag as seen
						points.push({ x: x2, y: y2 });
						seen[key] = true;
					}
				}

				return {
					x: minX,
					y: minY,
					width: maxX - minX,
					height: maxY - minY,
					coords: coords
				};
			}
		}; // End FloodFill

		function hexToRgb(hex, opacity) {
			opacity = Math.round(opacity * 255) || 255;
			hex = hex.replace("#", "");
			// let rgb = [],
			// 	re = new RegExp("(.{" + hex.length / 3 + "})", "g");
			// hex.match(re).map(function(l) {
			// 	rgb.push(parseInt(hex.length % 2 ? l + l : l, 16));
			// });

			let arrBuff = new ArrayBuffer(4);
			let vw = new DataView(arrBuff);
			vw.setUint32(0, parseInt(hex, 16), false);
			let arrByte = new Uint8Array(arrBuff);
			return [arrByte[1], arrByte[2], arrByte[3], opacity];
		}

		function hexify(color) {
			var values = color
				.replace(/rgba?\(/, "")
				.replace(/\)/, "")
				.replace(/[\s+]/g, "")
				.split(",");
			var a = parseFloat(values[3] || 1),
				r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
				g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
				b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255);
			return (
				"#" +
				("0" + r.toString(16)).slice(-2) +
				("0" + g.toString(16)).slice(-2) +
				("0" + b.toString(16)).slice(-2)
			);
		}


		function floodFill(enable) {
			if (!enable) {
				fcanvas.off("mouse:down");
				fcanvas.selection = true;
				fcanvas.forEachObject(function (object) {
					object.selectable = true;
				});
				return;
			}

			fcanvas.on({
				"mouse:down": function (e) {
					const shouldFillBackground = (props.selectedSpace === 'background' && !e.target) ? true : false;					
						if (shouldFillBackground) {
						// Create a rectangle object to fill the entire canvas
						const rect = new fabric.Rect({
							left: 0,
							top: 0,
							width: fcanvas.width,
							height: fcanvas.height,
							fill: fillColor,
							selectable: false,  // Disable selection if you don't want the rectangle to be selectable
							hasControls: false, // Disable controls for resizing
        					hoverCursor: 'default', // Ensure no hover effects
							evented: false,     // Prevent triggering further events on this object
						});
		
						// Add the rectangle to the canvas
						fcanvas.add(rect);
						fcanvas.bringToFront(rect); // Send it to the back if needed
						fcanvas.requestRenderAll();
						return;
					}
		
					// Existing logic for flood fill when user clicks on an object
					setTimeout(() => {
						if (e.target) {
							let mouse = fcanvas.getPointer(e.e),
								mouseX = Math.round(mouse.x),
								mouseY = Math.round(mouse.y),
								canvas = fcanvas.lowerCanvasEl,
								context = canvas.getContext("2d"),
								parsedColor = hexToRgb(fillColor),
								imageData = context.getImageData(0, 0, canvas.width, canvas.height),
								getPointOffset = function (x, y) {
									return 4 * (y * imageData.width + x);
								},
								targetOffset = getPointOffset(mouseX, mouseY),
								target = imageData.data.slice(targetOffset, targetOffset + 4);
		
							parsedColor[3] = Math.round(props.opacity * 255);
							let data = FloodFill.fill(
								imageData.data,
								getPointOffset,
								{ x: mouseX, y: mouseY },
								parsedColor,
								target,
								fillTolerance,
								imageData.width,
								imageData.height
							);
		
							if (0 == data.width || 0 == data.height || (data.x == 0 && data.y == 0)) {
								return;
							}
		
							let tmpCanvas = document.createElement("canvas"),
								tmpCtx = tmpCanvas.getContext("2d");
		
							tmpCanvas.width = canvas.width;
							tmpCanvas.height = canvas.height;
		
							let palette = tmpCtx.getImageData(0, 0, tmpCanvas.width, tmpCanvas.height);
							palette.data.set(new Uint8ClampedArray(data.coords));
							tmpCtx.putImageData(palette, 0, 0);
							let imgData = tmpCtx.getImageData(data.x, data.y, data.width, data.height);
		
							tmpCanvas.width = data.width;
							tmpCanvas.height = data.height;
							tmpCtx.putImageData(imgData, 0, 0);
							let img = new fabric.Image(tmpCanvas, {
								left: data.x,
								top: data.y,
								selectable: false,
							});
		
							// Add the new image to the canvas
							fcanvas.add(img);
						}
					}, 20);
				}
			});
		
		}

	}
}

export default PaintBucket;
