/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";

class Group extends FabricCanvasTool {
	configureCanvas(props) {
		let canvas = this._canvas;
		canvas.isDrawingMode = false;
		canvas.selection = true;
		canvas.forEachObject(o => {
			o.selectable = o.evented = o.hasControls = o.hasBorders = true;
			const activeObject = canvas.getActiveObject();
			if (!activeObject) {
				return;
			}
			if (activeObject.type === "activeSelection") {
				const group = activeObject.toGroup();

				// Enable caching for the group
				group.set("objectCaching", true);

				// Ensure the canvas updates visually
				canvas.requestRenderAll();
			}

		});

	}
}

export default Group;
